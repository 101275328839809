const institutes = [
    /* IITs  */
    //"INDIAN INSTITUTE OF TECHNOLOGY, TIRUPATI",
    //"INDIAN INSTITUTE OF TECHNOLOGY, GUWAHATI",
    //"INDIAN INSTITUTE OF TECHNOLOGY, PATNA",
    //"INDIAN INSTITUTE OF TECHNOLOGY, BHILAI",
    //"INDIAN INSTITUTE OF TECHNOLOGY, DELHI",
    //"INDIAN INSTITUTE OF TECHNOLOGY, GOA",
    //"INDIAN INSTITUTE OF TECHNOLOGY, GANDHI NAGAR",
    //"INDIAN INSTITUTE OF TECHNOLOGY, MANDI",
    //"INDIAN INSTITUTE OF TECHNOLOGY, JAMMU",
    //"INDIAN INSTITUTE OF TECHNOLOGY, DHANBAD",
    //"INDIAN INSTITUTE OF TECHNOLOGY, DHARWAD",
    //"INDIAN INSTITUTE OF TECHNOLOGY, PALAKKAD",
    //"INDIAN INSTITUTE OF TECHNOLOGY, INDORE",
    //"INDIAN INSTITUTE OF TECHNOLOGY, BOMBAY",
    //"INDIAN INSTITUTE OF TECHNOLOGY, BHUBANESHWAR",
    //"INDIAN INSTITUTE OF TECHNOLOGY, ROPAR",
    //"INDIAN INSTITUTE OF TECHNOLOGY, JODHPUR",
    //"INDIAN INSTITUTE OF TECHNOLOGY, MADRAS",
    //"INDIAN INSTITUTE OF TECHNOLOGY, HYDERABAD",
    //"INDIAN INSTITUTE OF TECHNOLOGY, KANPUR",
    //"INDIAN INSTITUTE OF TECHNOLOGY, VARANASI",
    //"INDIAN INSTITUTE OF TECHNOLOGY, ROORKEE",
    //"INDIAN INSTITUTE OF TECHNOLOGY, KHARAGPUR",

    /* BITs */
    "BIRLA INSTITUTE OF TECHNOLOGY, MESRA",
    "BIRLA INSTITUTE OF TECHNOLOGY, PATNA",
    "BIRLA INSTITUTE OF TECHNOLOGY, DEOGHAR",
    "BIRLA INSTITUTE OF TECHNOLOGY, LALPUR",
    "BIRLA INSTITUTE OF TECHNOLOGY, NOIDA",
    "BIRLA INSTITUTE OF TECHNOLOGY, JAIPUR",

    /* NITs */
    //"National Institute of Technology, Tadepalligudem,",
    //"NATIONAL INSTITUTE OF TECHNOLOGY YUPIA",
    //"NATIONAL INSTITUTE OF TECHNOLOGY SILCHAR",
    //"SATYAM INTERNATIONAL INSTITUTE OF TECHNOLOGY",
    //"NATIONAL INSTITUTE OF TECHNOLOGY PATNA",
    //"NATIONAL INSTITUTE OF TECHNOLOGY RAIPUR",
    //"NATIONAL INSTITUTE OF TECHNOLOGY DELHI",
    //"NATIONAL INSTITUTE OF TECHNOLOGY GOA",
    //"S V National Institute of Technology, Surat",
    //"SARDAR VALLABHBHAI NATIONAL INSTITUTE OF TECHNOLOGY SURAT",
    //"INTERNATIONAL INSTITUTE OF TECHNOLOGY & BUSINESS",
    //"INTERNATIONAL INSTITUTE OF TECHNOLOGY & MANAGEMENT",
    //"NATIONAL INSTITUTE OF TECHNOLOGY KURUKSHETRA",
    //"NATIONAL INSTITUTE OF TECHNOLOGY HAMIRPUR",
    //"NATIONAL INSTITUTE OF TECHNOLOGY HAZARATBAL, SRINAGAR",
    //"NATIONAL INSTITUTE OF TECHNOLOGY JAMSHEDPUR",
    //"NATIONAL INSTITUTE OF TECHNOLOGY SRINIVASANAGAR SURATHKAL",
    //"NATIONAL INSTITUTE OF TECHNOLOGY CALICUT",
    //"MAULANA AZAD NATIONAL INSTITUTE OF TECHNOLOGY BHOPAL",
    //"Visvesvaraya National Institute of Technology, Nagpur, Maharashtra",
    //"NATIONAL INSTITUTE OF TECHNOLOGY MANIPUR",
    //"NATIONAL INSTITUTE OF TECHNOLOGY MEGHALAYA",
    //"NATIONAL INSTITUTE OF TECHNOLOGY MIZORAM",
    //"NATIONAL INSTITUTE OF TECHNOLOGY NAGALAND",
    //"VISVESVARAYA NATIONAL INSTITUTE OF TECHNOLOGY",
    //"BARRISTER RANJIT MOHANTY INTERNATIONAL INSTITUTE OF TECHNOLOGY",
    //"NATIONAL INSTITUTE OF TECHNOLOGY ROURKELA",
    //"NATIONAL INSTITUTE OF TECHNOLOGY PUDUCHERRY",
    //"DR. B.R. AMBEDKAR NATIONAL INSTITUTE OF TECHNOLOGY",
    //"SINE INTERNATIONAL INSTITUTE OF TECHNOLOGY",
    //"MAHARISHI ARVIND INTERNATIONAL INSTITUTE OF TECHNOLOGY",
    //"MALAVIYA NATIONAL INSTITUTE OF TECHNOLOGY JAIPUR",
    //"NATIONAL INSTITUTE OF TECHNOLOGY SIKKIM",
    //"RAJAS INTERNATIONAL INSTITUTE OF TECHNOLOGY FOR WOMEN",
    //"NATIONAL INSTITUTE OF TECHNOLOGY TIRUCHIRAPALLI",
    //"SR INTERNATIONAL INSTITUTE OF TECHNOLOGY",
    //"NATIONAL INSTITUTE OF TECHNOLOGY WARANGAL",
    //"NATIONAL INSTITUTE OF TECHNOLOGY AGARTALA",
    //"BABU BANRASI DAS NATIONAL INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    //"BABU BANARASI DAS NATIONAL INSTITUTE OF TECHNOLOGY AND MANAGEMENT",
    //"MEERUT INTERNATIONAL INSTITUTE OF TECHNOLOGY",
    //"MOTILAL NEHRU NATIONAL INSTITUTE OF TECHNOLOGY ALLAHABAD",
    //"NATIONAL INSTITUTE OF TECHNOLOGY SRINAGAR (GARHWAL)",
    //"NATIONAL INSTITUTE OF TECHNOLOGY DURGAPUR",

    "SS JAIN SUBODH PG COLLEGE",
];

const educationLevel = [
    "School Student",
    "Undergraduate / Bachelor's",
    "Postgraduate / Master's",
    "PhD",
];

const degreePrograms = [
    "B.Tech Aerospace Engineering",
    "B.Tech Automotive Engineering",
    "B.Tech Chemical Engineering",
    "B.Tech Civil Engineering",
    "B.Tech Computer Science Engineering",
    "B.Tech Electrical Engineering",
    "B.Tech Electronics and Communication Engineering",
    "B.Tech Information Technology Engineering",
    "B.Tech Mechanical Engineering",
    "B.Tech Other",
    "B.Tech Petroleum Engineering",
    "B.Tech Software Engineering",
    "Bachelor of Arts",
    "Bachelor of Business Administration",
    "Bachelor of Commerce",
    "Bachelor of Computer Applications",
    "Bachelor of Design",
    "Bachelor of Education",
    "Bachelor of Hotel Management",
    "Bachelor of Laws",
    "Bachelor of Medicine, Bachelor of Surgery (MBBS)",
    "Bachelor of Pharmacy",
    "Bachelor of Science",
    "M.Tech Aerospace Engineering",
    "M.Tech Automotive Engineering",
    "M.Tech Chemical Engineering",
    "M.Tech Civil Engineering",
    "M.Tech Computer Science Engineering",
    "M.Tech Electrical Engineering",
    "M.Tech Electronics and Communication Engineering",
    "M.Tech Information Technology Engineering",
    "M.Tech Mechanical Engineering",
    "M.Tech Other",
    "M.Tech Petroleum Engineering",
    "M.Tech Software Engineering",
    "Master of Arts",
    "Master of Business Administration",
    "Master of Commerce",
    "Master of Computer Applications",
    "Master of Design",
    "Master of Education",
    "Master of Hotel Management",
    "Master of Laws",
    "Master of Pharmacy",
    "Master of Science",
];

const specialization = [
    "Accounts",
    "Accounts and Finance",
    "Agriculture",
    "Animation",
    "Artificial Intelligence",
    "Artificial Intelligence and Machine Learning",
    "Aviation",
    "Banking",
    "Banking and Finance",
    "Banking and Insurance",
    "Biochemistry",
    "Botany",
    "Chemistry",
    "Computer Networking and Security",
    "Computer Science",
    "Cyber Security",
    "Data Science",
    "Digital Marketing",
    "E-Commerce",
    "Entrepreneurship",
    "Fashion Technology",
    "Finance",
    "Food Technology",
    "Forensic Science",
    "Forestry",
    "Genetics",
    "Home Science",
    "Hospitality",
    "Human Resources",
    "Information Technologyy",
    "Interior Design",
    "International Business",
    "Internet of Things (IoT)",
    "Investment Management",
    "Journalism and Mass Communication",
    "LLB",
    "Marketing",
    "Medical Technology",
    "Microbiology",
    "Multimedia",
    "Nautical Science",
    "Nursing",
    "Physiotherapy",
    "Psychology",
    "Retail Management",
    "Sports Management",
    "Statistics",
    "Supply Chain",
    "Zoology",
    "No Specialization"
];

const organizations = ["Apple", "Google", "Microsoft", "Meta"];

const industry = [
    "Advertising/Marketing",
    "Aerospace/Aviation",
    "Agriculture",
    "Automotive",
    "Biotech and Pharmaceuticals",
    "Computers and Technology",
    "Construction",
    "Corporate Services",
    "Education",
    "Finance",
    "Government",
    "Healthcare/Medical",
    "Insurance",
    "Legal",
    "Manufacturing",
    "Media",
    "Non-Profit/Organizations",
    "Research",
    "Real Estate",
    "Retail and Consumer Goods",
    "Service Industry",
    "Telecommunications",
    "Transportation and Logistics",
    "Travel/Hospitality/Entertainment",
    "Utility/Energy",
];

const sizes = [
    "1 to 9 employees",
    "10 to 99 employees",
    "100 to 999 employees",
    "1,000 to 9,999 employees",
    "10,000 + employees",
];

const divisions = [
    "Core Business",
    "Customer Support",
    "Finance / Accounts",
    "Human Resources",
    "Legal / Law Compliance",
    "Management",
    "Manufacturing / Production",
    "Marketing / Promotion",
    "Product Research / Design / Development",
    "Public Relations",
    "Quality Assurance / Testing / Safety",
    "Sales",
    "Technical",
];

const positions = [
    "C-Level",
    "SVP / EVP",
    "Director / VP",
    "Manager / Supervisor",
    "Mid or Entry Level",
    "Freelance / Contract",
];

const preferredCountries = ["in", "us"];
export {
    institutes,
    educationLevel,
    degreePrograms,
    specialization,
    organizations,
    industry,
    sizes,
    divisions,
    positions,
    preferredCountries,
};
