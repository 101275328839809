import ProfessionalImage from "../../../assets/img/partners/professional.png";
import InternshipImage from "../../../assets/img/partners/experience.png";
import NetworkImage from "../../../assets/img/partners/network.png";
import EventsImage from "../../../assets/img/partners/events.png";
import JobImage from "../../../assets/img/partners/job.png";
import { aosDelay } from "../../../resources";
import React from "react";

export default function BenefitsPartnersSection() {
    return <React.Fragment>
        <section id="hero" className="hero partner-bg d-flex align-items-center">
            <div className="container">
                <div className="row sec">
                    <div
                        className="col-lg-6 hero-img"
                        data-aos="zoom-out"
                        data-aos-delay={aosDelay}
                    >
                        <img
                            src={InternshipImage}
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <div
                        className="col-lg-6 d-flex flex-column justify-content-center"
                    >
                        <h1 data-aos="fade-up" id="appHeading">
                            Experience certificate
                        </h1>
                        <h2 data-aos="fade-up" data-aos-delay={aosDelay}>
                            Being a TechVerse Lead earns you a TechVerse Lead Certificate, validating your leadership and community-building skills. It demonstrates your commitment to technology, enhances your professional profile, and can be a valuable addition to your resume or portfolio, boosting your credibility in future endeavors.
                        </h2>
                    </div>
                </div>
                <div className="row spacer sec">
                    <div
                        className="col-lg-6 hero-img"
                        data-aos="zoom-out"
                        data-aos-delay={aosDelay}
                    >
                        <img
                            src={ProfessionalImage}
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <div
                        className="col-lg-6 d-flex flex-column justify-content-center"
                    >
                        <h1 data-aos="fade-up" id="appHeading">
                            Professional growth
                        </h1>
                        <h2 data-aos="fade-up" data-aos-delay={aosDelay}>
                            Being a TechVerse Lead offers opportunities for professional growth through developing leadership skills, networking with industry professionals, gaining practical experience with technology, accessing mentorship from our experts, and enhancing your resume with valuable experience in community management and event organization.
                        </h2>
                    </div>
                </div>
                <div className="row spacer sec">
                    <div
                        className="col-lg-6 hero-img"
                        data-aos="zoom-out"
                        data-aos-delay={aosDelay}
                    >
                        <img
                            src={NetworkImage}
                            className="img-fluid"
                            alt=""
                        />
                    </div>

                    <div
                        className="col-lg-6 d-flex flex-column justify-content-center"
                    >
                        <h1 data-aos="fade-up" id="appHeading">
                            Network growth
                        </h1>
                        <h2 data-aos="fade-up" data-aos-delay={aosDelay}>
                            Being a TechVerse Lead facilitates network growth by connecting you with industry professionals and fellow students passionate about technology. It provides networking opportunities through events, collaborations, and mentorship, which can lead to valuable connections, internship/job opportunities, and a supportive tech community.
                        </h2>
                    </div>
                </div>
                <div className="row spacer sec">
                    <div
                        className="col-lg-6 hero-img"
                        data-aos="zoom-out"
                        data-aos-delay={aosDelay}
                    >
                        <img
                            src={JobImage}
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <div
                        className="col-lg-6 d-flex flex-column justify-content-center"
                    >
                        <h1 data-aos="fade-up" id="appHeading">
                            Internship and job opportunities
                        </h1>
                        <h2 data-aos="fade-up" data-aos-delay={aosDelay}>
                            Being a TechVerse Lead enhances job opportunities by providing practical experience, networking with industry professionals, and access to our resources. It showcases leadership, community management, and technical skills, making you more competitive in the job market and increasing your chances of securing internships or full-time positions.
                        </h2>
                    </div>
                </div>
                <div className="row spacer sec">
                    <div
                        className="col-lg-6 hero-img"
                        data-aos="zoom-out"
                        data-aos-delay={aosDelay}
                    >
                        <img
                            src={EventsImage}
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <div
                        className="col-lg-6 d-flex flex-column justify-content-center"
                    >
                        <h1 data-aos="fade-up" id="appHeading">
                            Access to exclusive venues and events
                        </h1>
                        <h2 data-aos="fade-up" data-aos-delay={aosDelay}>
                            Being a TechVerse Lead extends your privileges, you will have access to exclusive venues and events organised by us as well as other companies. These exclusive events provide opportunities to interact with and learn from industry experts. Such events are more often then not accompanied by banquets and temporary stay facilitated by us.
                        </h2>
                    </div>
                </div>
            </div>
        </section>
    </React.Fragment>
}