import { checkPermissionError, postRequestTo, preventDefault, showLoadingScreen } from "../../../resources";
import React, { useEffect, useState } from "react";

/* global Swal */
export default function PricingAdminPage(props) {

    const [state, setState] = useState({ links: {}, loading: true })

    useEffect(() => {
        const formData = new FormData();
        formData.append('Username', props.username);
        formData.append('Password', props.password);
        formData.append('Action', "RETRIVE_PRICING_INFO");

        postRequestTo(
            "admin",
            formData,
            function (response) {
                // console.log(response);
                const links = JSON.parse(response);
                setState({ ...state, links: links, loading: false })
            },
            function (response) {
                console.log(response)
            }
        );
        // eslint-disable-next-line
    }, []);

    function valueChange(e, k) {
        const v = e.target.value;
        const tempState = { ...state, links: { ...state.links } };
        tempState.links[k] = v;
        setState(tempState);
    }

    function saveChanges() {
        const formData = new FormData();
        formData.append('Username', props.username);
        formData.append('Password', props.password);
        formData.append('Action', "UPDATE_PRICING_INFO");
        formData.append('Data', JSON.stringify(state.links));
        showLoadingScreen("Saving Changes");

        postRequestTo(
            "admin",
            formData,
            function (response) {
                checkPermissionError(response);
                if (response.includes("all") && response.includes("saved")) {
                    Swal.fire(
                        'Saved',
                        `All changes have been saved`,
                        'success'
                    );
                }
            },
            function (response) {
                console.log(response)
            }
        );
    }

    return <React.Fragment>
        <div className="pagetitle">
            <h1>Pricing</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Admin</li>
                    <li className="breadcrumb-item">Superuser</li>
                    <li className="breadcrumb-item">Pricing</li>
                </ol>
            </nav>
        </div>
        {
            (state.loading) ?
                <div className="dashboardLoaderWidget">
                    <div className="d-flex justify-content-center mt-5 text-primary">
                        <div className="spinner-border" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </div> :
                <div className="card">
                    <div className="card-body">
                        <form onSubmit={preventDefault}>
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th scope="col">Particular</th>
                                        <th scope="col">Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.keys(state.links).map((key) => {
                                            return <tr key={key}>
                                                <td>{key}</td>
                                                <td><input value={state.links[key]} type="text" className="form-control" onChange={(e) => { valueChange(e, key) }} /></td>
                                            </tr>;
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className="col-sm-10">
                                <button type="submit" className="btn btn-primary" onClick={saveChanges}>Save Changes</button>
                            </div>
                        </form>
                    </div>
                </div>
        }
    </React.Fragment>
}