import WebPage from "../../components/webpage/WebPage";
import { csvJSON, mediaUrl, postRequestTo, preventDefault } from "../../resources";
import React, { useEffect, useState } from "react";
import "./cp.css";
import $ from "jquery";

export default function CertificatePortal(props) {

    const [state, setState] = useState({
        loaded: false,
        certificates: {},
        statusCSS: { display: "none" },
        certificateCSS: {},
        certificateID: (new URL(window.location.href).searchParams.get("CertificateID") === null || new URL(window.location.href).searchParams.get("CertificateID") === undefined || new URL(window.location.href).searchParams.get("CertificateID") === false) ? "" : new URL(window.location.href).searchParams.get("CertificateID"),
        showCertificate: false,
        certificateURL: ""
    })

    useEffect(() => {
        const formData = new FormData();
        postRequestTo(
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vQ6MRc_G8NpeN55o-TxcVjt2AVfx-WXaJKIvzFx7_4_AwxuVRgpwjEVpSxOuPhNmuWd9jRU5zT5RvNu/pub?output=csv",
            formData,
            (response) => {
                const json = csvJSON(response)
                if (response.includes("CertificateID")) {
                    const certificates = JSON.parse(json);
                    // console.log(certificates)
                    setState({
                        ...state,
                        loaded: true,
                        statusCSS: { display: "none" },
                        certificates: certificates,
                    })
                    search()
                }
            },
            (response) => {
                console.log(response);
            },
            true
        )
        // eslint-disable-next-line
    }, [])

    function onCertificateIDChange(e) {
        setState({ ...state, certificateID: e.target.value, statusCSS: { display: "none" } })
    }

    function search() {
        if (!state.loaded) {
            setTimeout(() => {
                $("#searchButton").trigger("click");
            }, 10);
            return;
        }
        if (!state.certificateID.replace(/\s/g, '').length) {
            return;
        }
        const certificateResults = state.certificates.filter((certificate) => {
            return certificate[0].toLowerCase() === state.certificateID.trim().toLowerCase()
        })
        if (certificateResults.length >= 1) {
            const certificate = certificateResults[0];
            // console.log(certificate);
            $.get(`${mediaUrl}certificates/${certificate[2]}.svg`, function (svg) {
                const svgXml = btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(svg))).replace(RegExp("{Name}", "ig"), certificate[1]));
                const certificateURL = `data:image/svg+xml;base64,${svgXml}`;
                setState({
                    ...state,
                    showCertificate: true,
                    certificateURL: certificateURL,
                    certificateCSS: {
                        background: `url(${certificateURL})`,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                    }
                })

            });
        } else {
            setState({ ...state, statusCSS: { display: "block" } });
        }
    }

    function print() {
        /* canvas */
        const c = document.createElement("canvas");
        c.setAttribute("crossorigin", "anonymous");
        c.width = 2000;
        c.height = 1414;

        /* context 2D */
        const ctx = c.getContext("2d");

        /* image */
        const img = document.createElement("img");
        img.setAttribute("crossorigin", "anonymous");
        img.src = state.certificateURL;
        document.body.append(img);

        img.onload = function () {
            ctx.drawImage(img, 0, 0);
            const data = c.toDataURL('image/png');
            download("certificate.png", data);
            img.remove();
        }
    }

    function download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', text);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    return <WebPage activePage="more">
        <section className="hero section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
                {
                    (!state.loaded) ?
                        <div className="dashboardLoaderWidget pricingLoaderWidget">
                            <div className="d-flex justify-content-center mt-5 text-primary">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        </div>
                        :
                        (!state.showCertificate) ?
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title text-center pb-0 fs-4 cpbh">Certificate Portal</h5>
                                            <p className="text-center small">Download, print or validate certificates issued by us</p>

                                            <form className="row g-3 needs-validation" noValidate="" onSubmit={preventDefault}>
                                                <div className="col-12">
                                                    <label htmlFor="CertificateID" className="form-label">Enter Certificate ID </label>
                                                    <div className="input-group has-validation">
                                                        <input type="text" name="CertificateID" className="form-control" id="CertificateID" value={state.certificateID} onChange={onCertificateIDChange} />
                                                        <div className="invalid-feedback" style={state.statusCSS}>Certificate not found .</div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <button className="btn btn-primary w-100" id="searchButton" type="submit" onClick={search}>Search</button>
                                                </div>
                                                <br />
                                                <div className="col-12">
                                                    <p className="small mb-0">The certificate portal is updated daily at 12am India Standard Time.</p>
                                                </div>
                                                <br />
                                                <br />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <React.Fragment>
                                <nav className="navbar bg-body-tertiary">
                                    <div className="container-fluid">
                                        Certificate {state.certificateID.toUpperCase()}
                                        <form className="d-flex" role="search" onSubmit={preventDefault}>
                                            <button className="btn btn-outline-primary" type="submit" onClick={(e) => { e.preventDefault(); print() }}> <i className="bi bi-download"></i> Download</button>
                                        </form>
                                    </div>
                                </nav>
                                <div className="certificateWindow" style={state.certificateCSS}></div>
                            </React.Fragment>
                }
            </div>
        </section>
    </WebPage>
}