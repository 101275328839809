import { Link } from "react-router-dom";

export default function FaqCommunitiesSection() {
    return <section id="faq" className="faq">
        <div className="container" data-aos="fade-up">
            <header className="section-header">
                <h2>Frequently Asked Questions</h2>
                <p>Your questions answered</p>
            </header>

            <div className="row">
                <div className="col-lg-6">
                    <div
                        className="accordion accordion-flush"
                        id="faqlist1"
                    >
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-1"
                                >
                                    What are TechVerse Communities?
                                </button>
                            </h2>
                            <div
                                id="faq-content-1"
                                className="accordion-collapse collapse"
                                data-bs-parent="#faqlist1"
                            >
                                <div className="accordion-body">
                                    TechVerse Communities are community groups for students and working professionals interested in various technologies. TechVerse Communities can be an excellent resource for anyone interested in technology, offering a range of benefits that can help develop and improve skills, build networks, grab the best opportunities and prepare for successful careers in the field.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-2"
                                >
                                    Who can join a TechVerse Community?
                                </button>
                            </h2>
                            <div
                                id="faq-content-2"
                                className="accordion-collapse collapse"
                                data-bs-parent="#faqlist1"
                            >
                                <div className="accordion-body">
                                    Anyone who has an interest in technology is welcome to join a TechVerse community. There are various in house TechVerse Communities of colleges / universities / high-schools as well as different companies / organizations. There are also various local / regional and global / international TechVerse Communities that one can join. You can join multiple communities to get the maximum exposure and claim the best opportunities.
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq-content-3"
                                >
                                    How to join a TechVerse Community?
                                </button>
                            </h2>
                            <div
                                id="faq-content-3"
                                className="accordion-collapse collapse"
                                data-bs-parent="#faqlist1"
                            >
                                <div className="accordion-body">
                                    If your institute or company already has a TechVerse community you can join it. If not you can join an International TechVerse community or lead a new TechVerse community by registering for TechVerse Partner Program.
                                    <br /> <br /> You can join multiple communitites to get the maximum exposure and expand your network.
                                    <br />
                                    <br /><Link to="/communities" target="_blank" rel="noopener noreferrer"> <span className="link">Explore and join Communities here <i className="bi bi-arrow-up-right"></i></span></Link>
                                    <br /><Link to="/partners" target="_blank" rel="noopener noreferrer"> <span className="link">Lead a community here <i className="bi bi-arrow-up-right"></i></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div
                        className="accordion accordion-flush"
                        id="faqlist2"
                    >
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq2-content-1"
                                >
                                    Who are TechVerse Leads / Community Partners?
                                </button>
                            </h2>
                            <div
                                id="faq2-content-1"
                                className="accordion-collapse collapse"
                                data-bs-parent="#faqlist2"
                            >
                                <div className="accordion-body">
                                    A TechVerse Lead is responsible for organizing and managing a community and planning and hosting events such as meetups, workshops, and hackathons, where members can come together to learn and collaborate.
                                    <br /> <br />
                                    The TechVerse Partner Program allows passionate individuals to become TechVerse Leads and create new or run existing TechVerse Communities.
                                    <br /> <br /><Link to="/partners" target="_blank" rel="noopener noreferrer"> <span className="link">TechVerse Partner Program <i className="bi bi-arrow-up-right"></i></span></Link>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq2-content-2"
                                >
                                    What are the advantages of becoming
                                    a TechVerse Lead?
                                </button>
                            </h2>
                            <div
                                id="faq2-content-2"
                                className="accordion-collapse collapse"
                                data-bs-parent="#faqlist2"
                            >
                                <div className="accordion-body">
                                    There are many advantages of becoming a TechVerse Lead like: <br /><br />
                                    <ul>
                                        <li>Experience Certificate</li>
                                        <li>Networking opportunities</li>
                                        <li>Professional growth</li>
                                        <li>Exclusive brand Deals</li>
                                        <li>Internship and job opportunities</li>
                                        <li>Access to exclusive venues and events</li>
                                        <li>And much more</li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button
                                    className="accordion-button collapsed"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#faq2-content-3"
                                >
                                    How to become a TechVerse Lead?
                                </button>
                            </h2>
                            <div
                                id="faq2-content-3"
                                className="accordion-collapse collapse"
                                data-bs-parent="#faqlist2"
                            >
                                <div className="accordion-body">
                                    You can become a TechVerse Lead by applying for the <Link to="/partners" target="_blank" rel="noopener noreferrer"> <span className="link">TechVerse Partner Program <i className="bi bi-arrow-up-right"></i></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
}