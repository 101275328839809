import { hrefValue, preventDefault } from "../../../resources";

export default function AdminDashboard(props) {
    return <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
            <li className="nav-heading">Admin</li>
            <li className="nav-item" onClick={() => { props.changePage("communitiesPage") }}>
                <a className={(props.currentPage === "communitiesPage") ? "nav-link active" : "nav-link"} href={hrefValue} onClick={preventDefault}>
                    <i className="bi bi-people"></i>
                    <span>Communities</span>
                </a>
            </li>

            <li className="nav-item" onClick={() => { props.changePage("linksPage") }}>
                <a className={(props.currentPage === "linksPage") ? "nav-link active" : "nav-link"} href={hrefValue} onClick={preventDefault}>
                    <i className="bi bi-box-arrow-up-right"></i>
                    <span>Links</span>
                </a>
            </li>

            <li className="nav-item" onClick={() => { props.changePage("statisticsPage") }}>
                <a className={(props.currentPage === "statisticsPage") ? "nav-link active" : "nav-link"} href={hrefValue} onClick={preventDefault}>
                    <i className="bi bi-bar-chart"></i>
                    <span>Statistics</span>
                </a>
            </li>

            {/* <li className="nav-item" onClick={() => { props.changePage("pricingPage") }}>
                <a className={(props.currentPage === "pricingPage") ? "nav-link active" : "nav-link"} href={hrefValue} onClick={preventDefault}>
                    <i className="bi bi-currency-rupee"></i>
                    <span>Pricing</span>
                </a>
            </li> */}

            <li className="nav-item" onClick={props.signOut}>
                <a className="nav-link" href={hrefValue} onClick={preventDefault}>
                    <i className="bi bi-box-arrow-in-right"></i>
                    <span>Logout</span>
                </a>
            </li>
        </ul>
    </aside>
}