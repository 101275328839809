export default function AdminLoginSection(props) {
    return <main>
        <section className="hero section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="pt-4 pb-2">
                                    <h5 className="card-title text-center pb-0 fs-4">Admin Login</h5>
                                    <p className="text-center small">Enter your username &amp; password to login</p>
                                </div>

                                <form className="row g-3 needs-validation" noValidate="" onSubmit={(e) => { e.preventDefault() }}>

                                    <div className="col-12">
                                        <label htmlFor="yourUsername" className="form-label">Username</label>
                                        <div className="input-group has-validation">
                                            <input type="text" name="username" className="form-control" id="yourUsername" value={props.username} onChange={props.changeUsername} />
                                            <div className="invalid-feedback">Please enter your username.</div>
                                        </div>
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="yourPassword" className="form-label">Password</label>
                                        <input type="password" name="password" className="form-control" id="yourPassword" value={props.password} onChange={props.changePassword} />
                                        <div className="invalid-feedback">Please enter your password!</div>
                                    </div>
                                    <br />
                                    <div className="col-12">
                                        <button className="btn btn-primary w-100" type="submit" onClick={props.signIn}>Login</button>
                                    </div>
                                    <div className="col-12">
                                        <p className="small mb-0">To recover password for admin account, contact the Tech Division</p>
                                    </div>
                                    <br />
                                    <br />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
}