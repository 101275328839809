import DashboardUserinterface from "../../components/dashboard/Dashboard";
import CommunitiesAdminPage from "./sections/CommunitiesAdminPage";
import StatisticsAdminPage from "./sections/StatisticsAdminPage";
import AdminLoginSection from "./sections/AdminLoginSection";
import PricingAdminPage from "./sections/PricingAdminPage";
import AdminDashboard from "./sections/AdminDashboard";
import LinksAdminPage from "./sections/LinksAdminPage";
import { postRequestTo } from "../../resources";
import React, { useState } from "react";
import $ from "jquery";
import './Admin.css';

/* global Swal */
export default function AdminPage() {

    const [state, setState] = useState({
        isLoggedIn: false,
        username: getUsername(),
        password: getPassword(),
        currentPage: "communitiesPage"
    });

    function changeUsername(e) {
        setState({ ...state, username: e.target.value })
    }

    function changePassword(e) {
        setState({ ...state, password: e.target.value })
    }

    function getUsername() {
        return (localStorage.getItem("aun") === null) ? "" : localStorage.getItem("aun");
    }

    function getPassword() {
        return "";
        // return (localStorage.getItem("apd") === null) ? "" : localStorage.getItem("apd");
    }

    function changePage(p) {
        if ($(window).width() < 768) {
            $("body").toggleClass("toggle-sidebar");
        }
        setState({ ...state, currentPage: p });
    }

    function signIn() {
        const formData = new FormData();
        formData.append('Username', state.username);
        formData.append('Password', state.password);
        formData.append('Action', "LOGIN");

        /* send request to correct service */
        postRequestTo(
            "admin",
            formData,
            success,
            function (response) {

            }
        );
    }

    function signOut() {
        setState({
            ...state,
            isLoggedIn: false,
            password: ""
        })
    }

    function success(response) {
        // console.log(response);
        if (response.includes("username") && response.includes("not")) {
            Swal.fire(
                'Username does not exist',
                `Check for any spelling mistakes in "${state.username}"`,
                'warning'
            )
        }
        if (response.includes("attemptLeft")) {
            const attemptLeft = JSON.parse(response)["attemptLeft"];
            Swal.fire(
                'Wrong password',
                `You have only ${parseInt(attemptLeft) + 1} attempts left`,
                'warning'
            )
        }
        if (response.includes("maximum")) {
            Swal.fire(
                'Wrong password limit exceeded',
                `Login is not available for this account as too any attempts were made to login with a wrong password, contact us to re-enable login for this account`,
                'warning'
            )
        }
        if (response.includes("allow")) {
            localStorage.setItem("aun", state.username)
            // localStorage.setItem("apd", state.password)
            setState({ ...state, isLoggedIn: true })
        }
    }

    return <DashboardUserinterface showButtons={state.isLoggedIn} signOut={signOut}>
        {
            (!state.isLoggedIn) ?
                <AdminLoginSection username={state.username} password={state.password} changeUsername={changeUsername} changePassword={changePassword} signIn={signIn} /> :
                <React.Fragment>
                    <div className="backgroundColor"></div>
                    <AdminDashboard signOut={signOut} currentPage={state.currentPage} changePage={changePage} />
                    <main id="main" className="main">
                        {
                            (state.currentPage === "communitiesPage") ? <CommunitiesAdminPage username={state.username} password={state.password} /> :
                                (state.currentPage === "linksPage") ? <LinksAdminPage username={state.username} password={state.password} /> :
                                    (state.currentPage === "statisticsPage") ? <StatisticsAdminPage username={state.username} password={state.password} /> :
                                        (state.currentPage === "pricingPage") ? <PricingAdminPage username={state.username} password={state.password} /> :
                                            <span></span>
                        }
                    </main>
                </React.Fragment>
        }
    </DashboardUserinterface>
}