import HeroImage from "../../../assets/img/home/hero.png";
import { aosDelay } from "../../../resources";
import { Link } from "react-router-dom";

export default function HeroSection() {
    return <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                    <h1 data-aos="fade-up">
                        Become a part of an amazing community of developers
                    </h1>
                    <h2 data-aos="fade-up" data-aos-delay={aosDelay}>
                        Expand your network. Grap the best opportunities
                    </h2>
                    <div data-aos="fade-up" data-aos-delay={aosDelay}>
                        <div className="text-center text-lg-start">
                            <a href="#about" className="btn-get-started d-inline-flex align-items-center justify-content-center align-self-center">
                                <span>Know More</span>
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                    {/* <div data-aos="fade-up" data-aos-delay={aosDelay}>
                        <div className="col-lg-10">
                            <form
                                className="subscribe-form"
                            >
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Enter email address"
                                    className="newsletter-email"
                                /><input type="submit" value="Subscribe" className="newsletter-subscribe" onClick={(e)=>{e.preventDefault()}}/>
                            </form>
                        </div>
                    </div> */}
                </div>
                <div
                    className="col-lg-6 hero-img"
                    data-aos="zoom-out"
                    data-aos-delay={aosDelay}
                >
                    <img
                        src={HeroImage}
                        className="img-fluid"
                        alt="Hero"
                    />
                </div>
            </div>
        </div>
    </section>
}