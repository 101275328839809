import { aosDelay, hrefValue, launchLink, preventDefault } from "../../resources";
import PartnerSectionImage from "../../assets/img/partners/partner-section.svg";
import PartnerHelpImage from "../../assets/img/partners/partner-help.svg";
import WebPage from "../../components/webpage/WebPage";
import './PartnerSection.css';

function ActionButton(o) {
    return <div data-aos="fade-up" data-aos-delay={aosDelay} className="aos-init aos-animate" >
        <div className="text-center text-lg-start" onClick={()=>{launchLink(o.linkName)}}>
            <a href={hrefValue} className="action-btn btn-get-started d-inline-flex align-items-center justify-content-center align-self-center" onClick={preventDefault}>
                <i className={`bi ${o.icon}`}></i>
                <span>{o.text}</span>
            </a>
        </div>
    </div>
}

export default function PartnerSectionPage() {
    return <WebPage activePage="partners" >
        <section id="hero" className="hero d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up">
                        <h1 id="appHeading">
                            Partner Section
                        </h1>
                        <h2>
                            Common actions
                        </h2>
                        <br />
                        <ActionButton text="Request new community group" icon="bi-plus-circle" linkName="request_new_group" />
                        <ActionButton text="Submit monthly report" icon="bi-calendar-check" linkName="submit_monthly_report" />
                        <ActionButton text="Submit event report" icon="bi-file-earmark-check" linkName="submit_event_report" />
                    </div>
                    <div
                        className="col-lg-6 hero-img"
                        data-aos="zoom-out"
                        data-aos-delay={aosDelay}
                    >
                        <img
                            src={PartnerSectionImage}
                            className="img-fluid partnerSectionImage"
                            alt="Hero"
                        />
                    </div>
                </div>
            </div>
        </section>
        <section id="hero" className="hero d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column justify-content-center" data-aos="fade-up">
                        <div>
                            <h1 id="appHeading" className="partnerSectionHeading">
                                Partner Help
                            </h1>
                            <h2>
                                Feel free to reach us
                            </h2>
                            <br />
                            <ActionButton text="Contact Partner Program Manager" icon="bi-telephone" linkName="contact_partner_program" />
                            <ActionButton text="Submit a suggestion " icon="bi-lightbulb" linkName="submit_suggestion" />
                            <ActionButton text="Report a problem" icon="bi-exclamation-triangle" linkName="report_problem" />
                        </div>
                    </div>
                    <div
                        className="col-lg-6 hero-img"
                        data-aos="zoom-out"
                        data-aos-delay={aosDelay}
                    >
                        <img
                            src={PartnerHelpImage}
                            className="img-fluid partnerSectionImage"
                            alt="Hero"
                        />
                    </div>
                </div>
            </div>
        </section>
    </WebPage>
}