// import UpcomingEvents from "../../components/global/upcomingEvents/UpcomingEvents";
// import TestimonialSection from "./sections/TestimonialSection";
// import Gallery from "../../components/global/gallery/Gallery";
// import ValuesSection from "./sections/ValuesSection";
// import FaqSection from "./sections/FaqSection";

import FaqCommunitiesSection from "../communities/sections/FaqCommunitiesSection";
import WebPage from "../../components/webpage/WebPage";
// import CountsSection from "./sections/CountsSection";
import AboutSection from "./sections/AboutSection";
import HeroSection from "./sections/HeroSection";
import { useEffect } from "react";
import "./Home.css";
import BenefitsCommunitiesSection from "../communities/sections/BenefitsCommunitiesSection";

export default function HomePage() {

    useEffect(() => {
        /* global Swiper */
        new Swiper('.clients-slider', {
            speed: 300,
            loop: true,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false
            },
            slidesPerView: 'auto',
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            breakpoints: {
                320: {
                    slidesPerView: 3,
                    spaceBetween: 30
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 60
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 80
                },
                992: {
                    slidesPerView: 6,
                    spaceBetween: 120
                }
            }
        });
        new Swiper('.testimonials-slider', {
            speed: 600,
            loop: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            slidesPerView: 'auto',
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            breakpoints: {
                320: {
                    slidesPerView: 1,
                    spaceBetween: 40
                },

                1200: {
                    slidesPerView: 3,
                }
            }
        });


    }, []);

    return <WebPage activePage="home" >
        <HeroSection />
        <main id="main">
            <AboutSection />
            {/* <CountsSection /> */}
            {/* <ValuesSection /> */}
            <BenefitsCommunitiesSection />
            <FaqCommunitiesSection />
            {/* <TestimonialSection /> */}
            {/* <UpcomingEvents /> */}
            {/* <Gallery />  */}
        </main>
    </WebPage>
}