import React, { useEffect } from "react";
import Navigation from "./Navigation";
import Footer from "./Footer";
import "./WebPage.css";
import Aos from "aos";

/* Export a wrapper component page that includes Navigation and Foooter */
export default function WebPage(props) {

    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    const onscroll = (el, listener) => {
        el.addEventListener("scroll", listener)
    }

    const on = (type, el, listener, all = false) => {
        if (all) {
            select(el, all).forEach(e => e.addEventListener(type, listener))
        } else {
            select(el, all).addEventListener(type, listener)
        }
    }

    useEffect(() => {
        let selectHeader = select("#header")
        if (selectHeader) {
            const headerScrolled = () => {
                if (window.scrollY > 50) {
                    selectHeader.classList.add("header-scrolled")
                } else {
                    selectHeader.classList.remove("header-scrolled")
                }
            }
            window.addEventListener("load", headerScrolled)
            onscroll(document, headerScrolled)
        }

        /* mobile navbar open and close */
        on('click', '.mobile-nav-toggle', function (e) {
            select('#navbar').classList.toggle('navbar-mobile')
            this.classList.toggle('bi-list')
            this.classList.toggle('bi-x')
        })

        /* mobile navbar dropdowns */
        on('click', '.navbar .dropdown > a', function (e) {
            if (select('#navbar').classList.contains('navbar-mobile')) {
                e.preventDefault()
                this.nextElementSibling.classList.toggle('dropdown-active')
            }
        }, true)

        /* aos */
        Aos.init({
            duration: 1000,
            easing: "ease-in-out",
            once: true,
            mirror: false
        });
        
        // eslint-disable-next-line 
    }, [])

    return <React.Fragment>
        <Navigation activePage={props.activePage} />
        {props.children}
        <Footer />
    </React.Fragment>
}