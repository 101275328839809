import HeroCommunitiesSection from "./sections/HeroPartnersSection";
import WebPage from "../../components/webpage/WebPage";
import './Partners.css';
import BenefitsPartnersSection from "./sections/BenefitsPartnersSection";

export default function PartnersPage() {
    return <WebPage activePage="partners" >
        <HeroCommunitiesSection />
        <BenefitsPartnersSection />
    </WebPage>
}