import $ from "jquery";

/* global Swal */

const aosDelay = 10;

const serverSideUrl = "https://techverse-server.000webhostapp.com/";
const mediaUrl = "https://raw.githubusercontent.com/techverselimited/media-api/main/";
const requestUrl = serverSideUrl + "request/";
const widgetsUrl = requestUrl + "widgets/";

const hrefValue = "";

const preventDefault = (e) => { e.preventDefault() }

const postRequestTo = function (url, data, success, error, custom) {
    $.ajax({
        url: (custom) ? encodeURI(url + "&reqID=" + unique(8)) : encodeURI(requestUrl + url + ".php?reqID=" + unique(8)),
        type: (custom) ? "get" : "post",
        data: data,
        success: success,
        error: (e) => {
            if (!navigator.onLine) {
                Swal.fire(
                    'Offline',
                    `The device has no active internet connection`,
                    'warning'
                )
            } else {
                Swal.fire(
                    'Trouble reaching our servers',
                    `Please try again after sometime`,
                    'warning'
                )
            }
            error(e);
        },
        processData: false,
        contentType: false,
    })
}

const csvJSON = function (csv) {

    var lines = csv.replace(/\r/g, "").split("\n");

    var result = [];

    var headers = lines[0].split(",");

    for (var i = 1; i < lines.length; i++) {

        var obj = [];
        var currentline = lines[i].split(",");

        for (var j = 0; j < headers.length; j++) {
            obj.push(currentline[j]);
        }

        result.push(obj);

    }

    return JSON.stringify(result); //JSON
}

const unique = (length) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

const launchLink = (d) => {
    window.open(`https://techverselimited.github.io/redirect-api/redirect.html?Destination=${encodeURIComponent(d)}&reqId=${encodeURIComponent(unique(8))}`, `_blank`)
}

const showLoadingScreen = (msg = "Loading") => {
    Swal.fire({
        title: msg,
        html: `
        <div class="msSpin">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
        `,
        allowEscapeKey: false,
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
    });
}


const checkPermissionError = (r) => {
    if (r.includes("PERMISSION_DENIED")) {
        Swal.fire(
            'Permission denied',
            `You do not have the permission to perform this action`,
            'warning'
        );
    }
}

export { aosDelay, mediaUrl, requestUrl, widgetsUrl, hrefValue, postRequestTo, csvJSON, preventDefault, unique, launchLink, showLoadingScreen, checkPermissionError }