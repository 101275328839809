import WebPage from "../../components/webpage/WebPage";

export default function PrivacyPolicy() {
    return <WebPage>
        <main id="main">
            <section className="legalPageWrap">
                <h2>Privacy Policy</h2>
                <br />
                <p>
                    <em>Last Updated: June 15, 2023</em>
                </p>
                <p>TechVerse, Ltd. (“ <strong>TechVerse</strong>” or “ <strong>we</strong>”, “ <strong>our</strong>” or “ <strong>us</strong>”) operates the TechVerse services, which include our communities, newsletters, websites, the TechVerse Store, paid products, merchandise, mobile apps and related social media pages (collectively, the “ <strong>Services</strong>”). </p>
                <p>We respect the privacy of users of the Services. This Privacy Policy supplements our Terms of Service and is intended to educate you on our policies and practices regarding the collection, use and disclosure of any Personal Information (defined below) and Anonymous Information (defined below).</p>
                <p>Please review this Privacy Policy carefully. By using or accessing the Services, signing up for newsletters or joining one or more of our communities and/or communicating with us, you are accepting the terms of this Privacy Policy.</p>
                <p>If you are a resident of California, the EEA, the UK, Switzerland, Canada, or Mexico please see the terms specific to California, European, Canadian&nbsp; and Mexican users, respectively, below.</p>
                <p>
                    <strong>1. USER CONSENT/LEGAL BASIS</strong>
                </p>
                <p>“ <strong>Personal Information</strong>” is information about you that is personally identifiable to you, like your name, address, and email address, as well as other non-public information that is associated with the foregoing. “ <strong>Anonymous Information</strong>” is information that is not associated with or linked to your Personal Information; Anonymous Information does not allow the identification of individual persons. We collect and use Personal Information and Anonymous Information as indicated in this Privacy Policy. </p>
                <p>When you submit any Personal Information through the Services, you agree to the terms of this Privacy Policy and you expressly consent to the treatment of your Personal Information defined in this Privacy Policy.</p>
                <p>Other legal basis for collecting Personal Information are: (i) where we need the Personal Information for performance of a contract or requested service; or (ii) where the collection and use is in our or another’s legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect the Personal Information in question. If we collect your Personal Information with your consent, you may have the right to withdraw your consent at any time as provided in the terms specific to California, European, Mexican and Canadian users, respectively, below.</p>
                <p>If you reside or are located outside of the United States, you understand and agree that we may (i) collect, use, disclose and otherwise process the Personal Information you provide even if you are located outside the United States and (ii) collect, use, maintain, store, disclose and otherwise process your Personal Information using servers and systems located within the United States.</p>
                <p>
                    <strong>2. INFORMATION COLLECTED</strong>
                </p>
                <p>We may collect Personal Information that you submit to us directly through the Services, and via passive technologies integrated into the Services, including via third parties.</p>
                <p>You may directly submit information to us when you subscribe to a newsletter or podcast, order a product (such as our paid products), purchase merchandise, answer survey questions, or use other methods of submitting information. The types of information you can choose to provide on the Services includes:</p>
                <ul>
                    <li>Name, email address, zip/postal code, physical location information and contact information.</li>
                    <li>Billing and shipping addresses, credit card number, and expiration date.</li>
                    <li>Personal Information you choose to provide, including your age, gender, race/ethnicity, job title and role, industry, and employer information.</li>
                </ul>
                <p>The Services also passively collect certain types of Personal Information and Anonymous Information, including through third parties. We may use pixel tags, cookies, and/or other similar technologies to collect this information. We do not use any such technologies to conduct online targeted advertising. The types of information collected passively through the Services includes:</p>
                <ul>
                    <li>Information relating to your devices, including: device model, operating system, browser type, unique device identifier, IP address, mobile phone number, mobile network carrier, and location (including application installations).</li>
                </ul>
                <p>Please also note that most web browsers automatically accept cookies and other tracking technologies, but if you prefer, you may be able to edit your browser options to block them. Visitors to the Services who disable cookies will be able to browse most areas of the Services, but some features may not function. You can find out more information about how to change your browser cookie settings at&nbsp; <a href="http://www.allaboutcookies.org/">www.allaboutcookies.org</a>. Further, the Services do not currently recognize “Do Not Track” settings or technologies. </p>
                <p>
                    <strong>3. USE OF INFORMATION</strong>
                </p>
                <p>We may use your Personal Information for the following general and business purposes:</p>
                <ul>
                    <li>To deliver Services.</li>
                    <li>To fulfill or respond to your inquiries.</li>
                    <li>To address administrative needs and communications.</li>
                    <li>To improve Services.</li>
                    <li>To contact you for internal business purposes or for marketing communications purposes. You may always choose not to receive marketing communications from TechVerse by following the unsubscribe instructions set out in our communications</li>
                    <li>To provide notice of changes and upgrades to Services.</li>
                </ul>
                <p>We may use both manual and automated systems, such as machine learning and artificial intelligence, to: analyze Personal Information and Anonymous Information to improve our Services; personalize your experience; automate certain aspects of the Services; aggregate and anonymize information; provide you certain features; and protect the safety and security of our Services.</p>
                <p>We use Anonymous Information such as web or mobile pages you have viewed, in order to analyze request and usage patterns so that we may improve our products and services, enhance our user experience, and gather broad demographic information for aggregate use.</p>
                <p>
                    <strong>4. INFORMATION SHARING</strong>
                </p>
                <p>We do not sell, trade, rent, or share your Personal Information with third parties for their marketing purposes, and we will not disclose financial information that is part of your Personal Information, in each case unless you request or authorize that we do so.</p>
                <p>i. Affiliated Entities</p>
                <p>We may disclose Personal Information to our corporate subsidiaries or affiliated entities which operate under our same internal processes and policies. Any Personal Information provided to our subsidiaries or entities affiliated with us will be treated by those subsidiaries and affiliated entities in accordance with the terms of this Privacy Policy.</p>
                <p>ii. Service Providers</p>
                <p>We may share your Personal Information with third party vendors and suppliers that help us provide our Services to you. These third parties provide services to us, such as email delivery services (for instance, to send you newsletters), data hosting, customer relationship management, and data analytics. You expressly consent to the sharing of your Personal Information with our contractors and third-party service providers (for the sole purpose of providing products and services to you. (Not applicable to Canadian consumers.) These service providers are contractually obligated to ensure the confidentiality of Personal Information and implement appropriate security measures.</p>
                <p>iii. Partner Companies</p>
                <p>We may participate in co-branding or co-promotional agreements with third parties pursuant to which we may share Personal Information with such third parties (“ <strong>Partner Companies</strong>”). For example, we may participate in agreements with Partner Companies pursuant to which we provide the Partner Companies with a URL and a customer registration page co-branded with, or private labeled by, the Partner Companies, and the Partner Companies distribute and promote the URL to its customers. A Partner Company may have access to Personal Information that we collect from their customers. As a result, if you register for the Services through a Partner Company, we may provide your Personal Information to the Partner Company. We do not control the privacy practices of these Partner Companies and we encourage you to review their privacy policies. </p>
                <p>Under the following scenarios, we may be required or authorized by law to share your Personal Information:</p>
                <ul>
                    <li>If we believe it is necessary to share information in order to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving possible threats to the physical safety of any person, violations of our Terms of Use or as otherwise required by law.</li>
                    <li>If we respond to subpoenas, court orders or legal process, or if we need to establish or exercise our legal rights or defend against legal claims.</li>
                    <li>If we believe it is necessary to restrict or inhibit any user from using any of the Services, including, without limitation, by means of “hacking” or defacing any portion thereof.</li>
                </ul>
                <p>We may use your Personal Information in order to provide to third parties non-personal aggregated information that does not allow you to be identified or contacted and that is combined with the Personal Information of other users (“ <strong>Aggregate Information</strong>”). For example, we might inform third parties regarding the number of users of the Services and the activities they conduct while on the Services. We might also inform a company that performs services or that provides products and/or services to us (that may or may not be a business partner or an advertiser on the Services) that “50% of our users live in the India” or that “15% of our users have purchased products and/or services which can be downloaded from the Services”. Depending on the circumstances, we may or may not charge third parties for this Aggregate Information. We may not limit the third parties’ use of the Aggregate Information. </p>
                <p>We may disclose Anonymous Information to third parties that promote our products and services. We reserve the right to use and disclose Anonymous Information to third parties at our discretion.</p>
                <p>
                    <strong>5. OPT-OUT OF MARKETING EMAILS OR NEWSLETTERS</strong>
                </p>
                <p>When you receive promotional communications or newsletters from us, you may “opt-out” by following the unsubscribe instructions indicated in the communication you receive. You may also opt-out of receiving newsletters and/or promotional communications by contacting us at <a href="mailto:techverselimited@gmail.com">techverselimited@gmail.com</a>&nbsp; <a href="https://forms.gle/qh8kWqfHRWt4tJwM8">Opt-Out Request Form</a>. We may continue to send you communications regarding the Services, such as notices about administrative updates, transaction reports, and changes to the Services, this Privacy Policy or the Terms of Service. Where applicable, please refer to our Privacy Notice. </p>
                <p>
                    <strong>6. CALIFORNIA PRIVACY RIGHTS</strong>
                </p>
                <p>
                    <em>California “Shine The Light”</em>&nbsp;– As provided by California Civil Code 1798.83, if you are a California resident, you have the right to receive (a) information identifying any third party company(ies) to whom we have disclosed your Personal Information to in the past year; and (b) a description of the categories of Personal Information disclosed. To obtain such information, please email your request to techverselimited@gmail.com with “California Privacy Rights Request” in the subject line.
                </p>
                <p>For California residents under the age of 18 and registered users, California law (Business and Professionals Code § 22581) provides that you can request the removal of content or information you posted on the App or Site. Any such request should be sent to us at techverselimited@gmail.com along with a description of the posted content or other information to be removed. Be advised, however, that applicable law may not permit us to completely or comprehensively remove your deleted content or for other reasons as set forth in this California law.</p>
                <p>
                    <em>California Consumer Privacy Act</em>&nbsp;– The California Consumer Privacy Act (CCPA) gives California consumers enhanced rights with respect to their personal information that is collected by businesses. The CCPA provides California consumers with three rights regarding their personal information.
                </p>
                <p>First, California consumers can opt out of the sale of their Personal Information. However, this option is not available as TechVerse does not sell your Personal Information.</p>
                <p>Second, California consumers can request to know:</p>
                <ol>
                    <li>What specific pieces of information a business has collected about the consumer;</li>
                    <li>Categories of personal information it has collected about the consumer;</li>
                    <li>Categories of sources from which the personal information is collected;</li>
                    <li>Categories of personal information that the business sold or disclosed for a business purpose about the consumer;</li>
                    <li>Categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</li>
                    <li>The business or commercial purpose for collecting or selling personal information.</li>
                </ol>
                <p>Third, California consumers can request that a business delete Personal Information about the consumer that a business has collected from the consumer.</p>
                <p>TechVerse is a covered business under the CCPA as it collects and processes the Personal Information of California consumers. This Privacy Policy provides certain required notices to California consumers. The categories of Personal Information we collect and disclose for business purposes in the preceding 12 months are described above. The CCPA also prohibits covered businesses from providing discriminatory treatment to California consumers if they exercise their rights under the CCPA.</p>
                <p>To make a “Request to know” or “Request to Delete” your personal information, please contact us at <a href="mailto:techverselimited@gmail.com">techverselimited@gmail.com</a>. Authorized agents may also complete and submit the interactive Request Form on behalf of a California Consumer. </p>
                <p>We will confirm receipt of your request within 10 business days. We must provide the requested information or delete your personal information within 45 days of receipt of your request, but we can use up to an additional 45 days if we let you know that additional time is needed.</p>
                <p>Before responding, we must verify that the person making the request is the person about whom we have collected their personal information. We may ask you to provide certain, limited personal information, such as your name and email address to verify and match your identity with our records and systems. If you have an account with us, we may ask that you verify your identity through our account authentication process. This is also to protect against fraud. We will not retain this personal information or use it for any other purpose. Also please be advised that we need to search our records and systems only for the preceding 12 months. There may be cases where we do not have any personal information about you or we are not able to verify your identity for matching purposes. Deleting your Personal Information may result in the closing of your account and inability to access the Services. However, you can always re-register at any time.</p>
                <p>(Residents of Nevada and other states may also have similar rights to request information about or delete their personal information. To inquire about exercising these rights, please contact us at techverselimited@gmail.com.)</p>
                <p>
                    <strong>7. LOCATED IN THE UNITED STATES</strong>
                </p>
                <p>TechVerse and the Services are located in the United States and governed by US law. If you are outside the United States when you visit the Services or engage in communications with us, please be aware that your Personal Information may be transferred to, stored and processed in the United States where our servers are located. Any information you provide to us, or that we collect through your use of the Services and will be stored, processed, and transferred within, or to, the United States. Please be aware that the United States and jurisdictions other than the one in which you are located may not provide the same level of data protection as considered adequate in your country. Note that your Personal Information may be available to the US Government or its agencies under legal process in the United States.</p>
                <p>
                    <strong>8. USERS IN THE EUROPEAN ECONOMIC AREA&nbsp;</strong>( <strong>EEA</strong>) <strong>, UNITED KINGDOM AND SWITZERLAND</strong>
                </p>
                <p>
                    <strong>
                        <em>The Legal Basis for Using EEA, UK and Swiss Personal Information</em>
                    </strong>
                </p>
                <p>For residents of the European Economic Area (EEA), United Kingdom and Switzerland, we advise that your Personal Information will be transferred to and processed in the United States, which has data protection laws that are different than those in your country and may not be as protective. The United States has not sought or received a finding of “adequacy” from the European Union under Article 45 of the General Data Protection Regulation (“ <strong>GDPR</strong>”). Our legal basis for collecting and using your Personal Information is to do so with your consent; where we need the Personal Information for performance of a contract or requested service, including to provide you with our daily Newsletter, or where the collection and use is in our or another’s legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect the Personal Information in question. If we collected your Personal Information with your consent, you may withdraw your consent at any time. </p>
                <p>To exercise rights under the GDPR, please contact us at <a href="mailto:techverselimited@gmail.com">techverselimited@gmail.com</a>. </p>
                <p>Our retention of your Personal Information and any subsequent communications are based on our legitimate interest in providing you with new and potentially relevant materials based on your geography, role, or company. As always, you can elect to opt out from receiving such future communications.</p>
                <p>To the extent that we transfer Personal Information from the EEA, UK or Switzerland to a jurisdiction outside the EEA, UK or Switzerland that has not been adduced by the European Commission as providing adequate data protections (such as the United States), we will ensure that such Personal Information is safeguarded through appropriate contractual terms or other approved mechanisms.</p>
                <p>
                    <strong>
                        <em>Rights for EEA, UK or Swiss Residents</em>
                    </strong>
                </p>
                <p>In addition, if you are a resident of the EEA, UK or Switzerland, you have the right to:</p>
                <ul>
                    <li>Find out if we use your Personal Information, to access your Personal Information, and receive copies of your Personal Information.</li>
                    <li>Withdraw any express consent that you have provided to the processing of your Personal Information at any time without penalty.</li>
                    <li>Access your Personal Information and have it corrected or amended if it is inaccurate or incomplete.</li>
                    <li>Obtain a transferable copy of some of your Personal Information which can be transferred to another provider when the Personal Information was processed based on your consent.</li>
                    <li>If you believe your Personal Information is inaccurate, no longer necessary for our business purposes, or if you object to our processing of your Personal Information, you also have the right to request that we restrict the processing of your data pending our investigation and/or verification of your claim.</li>
                    <li>Request your Personal Information be deleted or restricted under certain circumstances. For example, if a business is using your Personal Information on the basis of your consent and has no other legal basis to use such, you may request your Personal Information be deleted when you withdraw your consent.</li>
                </ul>
                <p>Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your Personal Information conducted in reliance on lawful processing grounds other than consent.</p>
                <p>If we ask you to provide Personal Information to us to comply with a legal requirement or enter into a contract, we will inform you of this and let you know whether providing us with your Personal Information is required and if not, the consequences of not sharing your Personal Information with us.</p>
                <p>Similarly, if we collect and use your Personal Information in reliance on our or a third party’s legitimate interests and those interests are not already described above, we will let you know what those legitimate interests are.</p>
                <p>We endeavor to apply suitable safeguards to protect the privacy and security of your Personal Information and to use it only consistent with your relationship with us and the practices described in this Privacy Policy.</p>
                <p>To withdraw consent or exercise these rights, please contact us via email at techverselimited@gmail.com.</p>
                <p>If you are not satisfied with our response, or believe we are processing your Personal Information in violation of the law, you have the right to lodge a complaint with the Supervisory Authority (also known as Data Protection Authority) or other appropriate governmental authority in your EEA Member State or Switzerland.</p>
                <p>
                    <strong>9. USERS IN CANADA</strong>
                </p>
                <p>If you reside in Canada, you have the right to request access to your Personal Information and request the correction of your Personal Information, subject to limited exceptions set out in applicable laws.</p>
                <p>You may also withdraw your consent to the collection, use and disclosure of Personal Information at any time, subject to legal or contractual restrictions. Withdrawal of your consent may affect our ability to continue to provide you with the Services that you have or would like to receive because the continued use and disclosure of your Personal Information is a necessary part of making the product or service available to you.</p>
                <p>In order to exercise these rights, please contact us via email at <a href="mailto:techverselimited@gmail.com">techverselimited@gmail.com</a>. </p>
                <p>
                    <strong>10. SECURITY</strong>
                </p>
                <p>TechVerse has implemented technical, administrative and physical security measures to protect your information from loss or theft, as well as unauthorized access, use or disclosure. While we strive to protect your information, no data transmission over the Internet is 100% secure and, consequently, we cannot guarantee or warrant the security of any information you provide, and you do so at your own risk. We cannot promise that your information will remain absolutely secure in all circumstances. We are not responsible for the circumvention of any privacy settings or security measures we may provide.</p>
                <p>We will retain your information for the time necessary to provide a requested service, realize our legitimate business purposes and to comply with the law. We will only retain personal information for the longer of the period if: (a) required by law, or (b) there is a time-bound determination business need.</p>
                <p>
                    <strong>11. CHILDREN’S INFORMATION</strong>
                </p>
                <p>The Services are not intended to be used by children under the age of 13 if you are in the USA or the UK, under the age of 18 if you are in Mexico, or under the age of 16 if are located anywhere else, and we will not knowingly collect Personal Information from children under these ages (as applicable). If you are under 18 years old or the age of majority in your jurisdiction of residence (whichever is higher), you will require the consent of your parent or legal guardian to agree to your use of the Services and to provide your Personal Information to us. We do not use an application or other mechanism to determine the age of users of the Services. All information provided to TechVerse will be treated as if it was provided by an adult. If, however, we learn that a child has submitted information about himself/herself to us, we will delete the information as soon as possible.</p>
                <p>
                    <strong>12. LINKS TO THIRD-PARTY WEBSITES</strong>
                </p>
                <p>The Services may contain links to and from third party websites of our business partners and various contact management services and other service providers. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for their policies. Please check their individual privacy policies before you submit any Personal Information to those websites.</p>
                <p>Our provision of a link to any other website or location is for your convenience and does not indicate our endorsement of such other website or location or its contents. We have no control over, do not review, and cannot be responsible for these outside websites or their content. Please be aware that the terms of our Privacy Policy do not apply to these outside websites.</p>
                <p>
                    <strong>13. UPDATES TO PRIVACY POLICY</strong>
                </p>
                <p>We may modify this Privacy Policy from time to time by posting updates on this page. If we make any significant changes to this Privacy Policy, we will contact you to inform you when required by law.</p>
                <p>
                    <strong>14. CONTACT US</strong>
                </p>
                <p>Should you have any questions, concerns or feedback regarding this Privacy Policy, please contact us by email at <a href="mailto:techverselimited@gmail.com">techverselimited@gmail.com</a>. </p>
            </section>
        </main>
    </WebPage>
}