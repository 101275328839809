import {
    degreePrograms,
    divisions,
    educationLevel,
    institutes,
    organizations,
    positions,
    industry,
    sizes,
    specialization,
} from "./SubscribeFormData";

const years = Array.from(
    { length: 60 },
    (_, i) => new Date().getFullYear() + 10 - i
);

const Step1 = (
    <div className="card mb-3 crdfm formStep1">
        <div className="card-body">
            <div>
                <div className="style__ProgressBarContainer-sc-3bfcffca-1 brPyfP">
                    <div className="style__ProgressBars-sc-3bfcffca-2 kxAoka">
                        <div className="form-progress-bar 1 c"></div>
                        <div className="form-progress-bar 2"></div>
                        <div className="form-progress-bar 3"></div>
                    </div>
                    <p className="sc-irTswW dKuTtm">Step 1 of 3</p>
                </div>
            </div>
            <form className="row g-3 needs-validation" noValidate="">
                <div className="col-12">
                    <label htmlFor="firstName" className="form-label">
                        First Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        id="firstName"
                    />
                    <div className="invalid-feedback">
                        Enter your first name!
                    </div>
                </div>

                <div className="col-12">
                    <label htmlFor="lastName" className="form-label">
                        Last Name
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        id="lastName"
                    />
                    <div className="invalid-feedback">
                        Enter your last name!
                    </div>
                </div>
                <div className="col-12">
                    <label htmlFor="occupation" className="form-label">
                        I am a
                    </label>
                    <select
                        className="form-select"
                        defaultValue="select"
                        id="occupation"
                    >
                        <option value="select" disabled>
                            Select
                        </option>
                        <option value="student_or_intern">
                            Student / Intern
                        </option>
                        <option value="working_professional_or_employee">
                            Working Professional / Employee
                        </option>
                    </select>
                    <div className="invalid-feedback">Please, select!</div>
                </div>
                <div className="col-12">
                    <button
                        className="btn btn-primary w-100 next-btn"
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    >
                        Next
                    </button>
                </div>
            </form>
        </div>
    </div>
);

const Step2Student = (
    <div className="card mb-3 crdfm formStep2Student">
        <div className="card-body">
            <div>
                <div className="style__ProgressBarContainer-sc-3bfcffca-1 brPyfP">
                    <div className="style__ProgressBars-sc-3bfcffca-2 kxAoka">
                        <div className="form-progress-bar 1 c"></div>
                        <div className="form-progress-bar 2 c"></div>
                        <div className="form-progress-bar 3"></div>
                    </div>
                    <p className="sc-irTswW dKuTtm">Step 2 of 3</p>
                </div>
            </div>
            <form className="row g-3 needs-validation" noValidate="">
                <div className="col-12">
                    <label htmlFor="institute" className="form-label">
                        Institute Name (College / University / School)
                    </label>
                    <input
                        type="text"
                        className="form-control instituteName"
                        id="institute"
                        autoComplete="on"
                        list="institutes"
                    />
                    <datalist id="institutes">
                        {institutes.map((o) => {
                            return (
                                <option value={o} key={o}>
                                    {o}
                                </option>
                            );
                        })}
                    </datalist>
                    <div className="invalid-feedback">
                        Please, enter institute name!
                    </div>
                </div>

                <div className="col-md-6">
                    <label htmlFor="educationLevel" className="form-label">
                        Education Level
                    </label>
                    <select
                        className="form-select"
                        defaultValue="select"
                        id="educationLevel"
                    >
                        <option value="select" disabled>
                            Select
                        </option>
                        {educationLevel.map((o) => {
                            return (
                                <option value={o} key={o}>
                                    {o}
                                </option>
                            );
                        })}
                        <option value="other">Other</option>
                    </select>
                    <div className="invalid-feedback">Please, select!</div>
                </div>

                <div className="col-md-6">
                    <label htmlFor="educationLevel" className="form-label">
                        Graduation Year
                    </label>
                    <select
                        className="form-select"
                        defaultValue="select"
                        id="graduationYear"
                    >
                        <option value="select" disabled>
                            Select
                        </option>
                        {years.map((year) => {
                            return (
                                <option value={year} key={year}>
                                    {year}
                                </option>
                            );
                        })}
                    </select>
                    <div className="invalid-feedback">Please, select!</div>
                </div>
                <div className="col-md-6">
                    <label htmlFor="qualification" className="form-label">
                        Qualification
                    </label>
                    <select
                        className="form-select"
                        defaultValue="select"
                        id="qualification"
                    >
                        <option value="select" disabled>
                            Select
                        </option>
                        {degreePrograms.map((o) => {
                            return (
                                <option value={o} key={o}>
                                    {o}
                                </option>
                            );
                        })}
                        <option value="other">Other</option>
                    </select>
                    <div className="invalid-feedback">Please, select!</div>
                </div>
                <div className="col-md-6">
                    <label htmlFor="specialization" className="form-label">
                        Specialization
                    </label>
                    <select
                        className="form-select"
                        defaultValue="select"
                        id="specialization"
                    >
                        <option value="select" disabled>
                            Select
                        </option>
                        {specialization.map((o) => {
                            return (
                                <option value={o} key={o}>
                                    {o}
                                </option>
                            );
                        })}
                        <option value="other">Other</option>
                    </select>
                    <div className="invalid-feedback">Please, select!</div>
                </div>
                <div className="col-12">
                    <button
                        className="btn btn-primary w-100 next-btn"
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    >
                        Next
                    </button>
                    <button
                        className="btn btn-light btbc w-100"
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    >
                        Back
                    </button>
                </div>
            </form>
        </div>
    </div>
);

const Step2WorkingProfessional = (
    <div className="card mb-3 crdfm formStep2Employee">
        <div className="card-body">
            <div>
                <div className="style__ProgressBarContainer-sc-3bfcffca-1 brPyfP">
                    <div className="style__ProgressBars-sc-3bfcffca-2 kxAoka">
                        <div className="form-progress-bar 1 c"></div>
                        <div className="form-progress-bar 2 c"></div>
                        <div className="form-progress-bar 3"></div>
                    </div>
                    <p className="sc-irTswW dKuTtm">Step 2 of 3</p>
                </div>
            </div>
            <form className="row g-3 needs-validation" noValidate="">
                <div className="col-12">
                    <label htmlFor="organization" className="form-label">
                        Organization Name
                    </label>
                    <input
                        type="text"
                        className="form-control instituteName"
                        id="organization"
                        autoComplete="on"
                        list="organizations"
                    />
                    <datalist id="organizations">
                        {organizations.map((o) => {
                            return (
                                <option value={o} key={o}>
                                    {o}
                                </option>
                            );
                        })}
                    </datalist>
                    <div className="invalid-feedback">
                        Please, enter organization name!
                    </div>
                </div>

                <div className="col-md-6">
                    <label htmlFor="industry" className="form-label">
                        Industry
                    </label>
                    <select
                        className="form-select"
                        defaultValue="select"
                        id="industry"
                    >
                        <option value="select" disabled>
                            Select
                        </option>
                        {industry.map((o) => {
                            return (
                                <option value={o} key={o}>
                                    {o}
                                </option>
                            );
                        })}
                        <option value="other">Other</option>
                    </select>
                    <div className="invalid-feedback">Please, select!</div>
                </div>

                <div className="col-md-6">
                    <label htmlFor="companySize" className="form-label">
                        Company Size
                    </label>
                    <select
                        className="form-select"
                        defaultValue="select"
                        id="companySize"
                    >
                        <option value="select" disabled>
                            Select
                        </option>
                        {sizes.map((year) => {
                            return (
                                <option value={year} key={year}>
                                    {year}
                                </option>
                            );
                        })}
                    </select>
                    <div className="invalid-feedback">Please, select!</div>
                </div>
                <div className="col-md-6">
                    <label htmlFor="division" className="form-label">
                        Department
                    </label>
                    <select
                        className="form-select"
                        defaultValue="select"
                        id="division"
                    >
                        <option value="select" disabled>
                            Select
                        </option>
                        {divisions.map((o) => {
                            return (
                                <option value={o} key={o}>
                                    {o}
                                </option>
                            );
                        })}
                        <option value="other">Other</option>
                    </select>
                    <div className="invalid-feedback">Please, select!</div>
                </div>
                <div className="col-md-6">
                    <label htmlFor="position" className="form-label">
                        Job Level
                    </label>
                    <select
                        className="form-select"
                        defaultValue="select"
                        id="position"
                    >
                        <option value="select" disabled>
                            Select
                        </option>
                        {positions.map((o) => {
                            return (
                                <option value={o} key={o}>
                                    {o}
                                </option>
                            );
                        })}
                        <option value="other">Other</option>
                    </select>
                    <div className="invalid-feedback">Please, select!</div>
                </div>
                <div className="col-12">
                    <button
                        className="btn btn-primary w-100 next-btn"
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    >
                        Next
                    </button>
                    <button
                        className="btn btn-light btbc w-100"
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    >
                        Back
                    </button>
                </div>
            </form>
        </div>
    </div>
);

const Step3 = (
    <div className="card mb-3 crdfm formStep3">
        <div className="card-body">
            <div>
                <div className="style__ProgressBarContainer-sc-3bfcffca-1 brPyfP">
                    <div className="style__ProgressBars-sc-3bfcffca-2 kxAoka">
                        <div className="form-progress-bar 1 c"></div>
                        <div className="form-progress-bar 2 c"></div>
                        <div className="form-progress-bar 3 c"></div>
                    </div>
                    <p className="sc-irTswW dKuTtm">Step 3 of 3</p>
                </div>
            </div>
            <form className="row g-3 needs-validation" noValidate="">
                <div className="col-12">
                    <label htmlFor="phoneNumber" className="form-label">
                        Phone Number
                    </label>
                    <input
                        type="tel"
                        className="form-control"
                        placeholder="000 000 0000"
                        id="phoneNumber"
                    />
                    <div className="invalid-feedback">
                        Please, enter your name!
                    </div>
                </div>

                <div className="col-12">
                    <label htmlFor="postalCode" className="form-label">
                        Postal code (Pin code)
                    </label>
                    <input
                        type="number"
                        className="form-control"
                        placeholder=""
                        id="postalCode"
                    />
                    <div className="invalid-feedback">
                        Please, enter your name!
                    </div>
                </div>
                <div className="col-12">
                    <label htmlFor="addToCommunity" className="form-label">
                        I have WhatsApp
                    </label>
                    <select
                        className="form-select"
                        defaultValue="y"
                        id="addToCommunity"
                    >
                        <option value="y">Yes</option>
                        <option value="n">No</option>
                    </select>
                    <div className="invalid-feedback">Please, select!</div>
                </div>
                <div className="col-12">
                    <button
                        className="btn btn-primary w-100 submit-subscribe-form"
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    >
                        Submit
                    </button>
                    <button
                        className="btn btn-light btbc w-100"
                        onClick={(e) => {
                            e.preventDefault();
                        }}
                    >
                        Back
                    </button>
                </div>
            </form>
        </div>
    </div>
);

export { Step1, Step2Student, Step2WorkingProfessional, Step3 };
