import MissionIamge from "../../../assets/img/home/mission.png";

export default function AboutSection() {
    return (
        <section id="about" className="about">
            <section id="clients" className="clients">
                <div className="container" data-aos="fade-up">
                    <header className="section-header">
                        <h2 id="headingMutate">Our Users</h2>
                        <p>Loved by people from</p>
                    </header>

                    <div className="clients-slider swiper">
                        <div className="swiper-wrapper align-items-center">
                            <div className="swiper-slide">
                                <img
                                    src="assets/img/clients/infosys.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <div className="swiper-slide">
                                <img
                                    src="assets/img/clients/zomato.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <div className="swiper-slide">
                                <img
                                    src="assets/img/clients/microsoft.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <div className="swiper-slide">
                                <img
                                    src="assets/img/clients/oneplus.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <div className="swiper-slide">
                                <img
                                    src="assets/img/clients/apple.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <div className="swiper-slide">
                                <img
                                    src="assets/img/clients/flipkart.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <div className="swiper-slide">
                                <img
                                    src="assets/img/clients/google.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                            <div className="swiper-slide">
                                <img
                                    src="assets/img/clients/realme.svg"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
            </section>
            <div className="container" data-aos="fade-up">
                <div className="row gx-0">
                    <div
                        className="col-lg-6 d-flex flex-column justify-content-center"
                        data-aos="fade-up"
                        data-aos-delay="200"
                    >
                        <div className="content">
                            <h3>our mission</h3>
                            <h2>Technology Ecosystem</h2>
                            <p>
                                We aim to build dynamic and inclusive
                                technology communities that foster innovation,
                                knowledge sharing, and collaboration, empowering
                                individuals to shape a better future through
                                technology.
                                <br /> <br />
                                Our mission is to keep our members informed
                                on the latest advancements, trends
                                and opportunities in the technology industry. We
                                aim to deliver high-quality content that
                                educates, engages, and empowers our members and
                                helps them stay ahead of the curve in the
                                ever-evolving tech landscape.
                            </p>
                            <div className="text-center text-lg-start">
                                {/* <a
                                    href={hrefValue}
                                    className="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center"
                                >
                                    <span>Read More</span>
                                    <i className="bi bi-arrow-right"></i>
                                </a> */}
                            </div>
                        </div>
                    </div>

                    <div
                        className="col-lg-6 d-flex align-items-center"
                        data-aos="zoom-out"
                        data-aos-delay="200"
                    >
                        <img src={MissionIamge} className="img-fluid" alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
}
