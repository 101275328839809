import { Link } from "react-router-dom";
import React from "react";
import "./Dashboard.css";
import $ from "jquery";

export default function DashboardUserinterface(props) {
    return <div className="dashboardUserInterface">
        <header id="header" className="header fixed-top d-flex align-items-center">
            <div className="d-flex align-items-center justify-content-between">
                <Link to="/" className="logo d-flex align-items-center">
                    <img src="/assets/img/icon/icon.svg" alt="TechVerse Logo" />
                    <span className="d-none d-lg-block">TechVerse</span>
                </Link>
                {(props.showButtons) ? <i className="bi bi-list toggle-sidebar-btn" onClick={() => { $("body").toggleClass("toggle-sidebar") }}></i> : <span></span>}
            </div>

            <nav className="header-nav ms-auto">
                {(props.showButtons) ? <span onClick={props.signOut}>
                    <Link className="getstarted logout-btn scrollto">
                        Logout &nbsp; <i className="bi bi-box-arrow-right"></i>
                    </Link>
                </span> : <span></span>}
            </nav>
        </header>
        {props.children}
    </div>
}