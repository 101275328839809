import { checkPermissionError, postRequestTo, preventDefault, showLoadingScreen } from "../../../resources";
import React, { useState } from "react";

/* global Swal */
export default function CommunitiesAdminPage(props) {

    const [state, setState] = useState({
        newCommunity: {
            name: "",
            location: "",
            size: "",
            email: "",
            phoneNumber: "",
            whatsAppLink: "",
            website: "",
            id: "",
        },
        modifyCommunity: {
            name: "",
            location: "",
            size: "",
            email: "",
            phoneNumber: "",
            whatsAppLink: "",
            website: "",
            id: "",
        },
        found: false,
        search: "",
    })

    function validString(str) {
        return str.replace(/\s/g, "").length;
    }

    function validEmail(email) {
        return String(email).toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    function createNewCommunity() {
        const formData = new FormData();
        formData.append('Username', props.username);
        formData.append('Password', props.password);
        formData.append('Action', "CREATE_NEW_COMMUNITY");
        formData.append('Name', state.newCommunity.name);
        formData.append('Location', state.newCommunity.location);
        formData.append('Size', state.newCommunity.size);
        formData.append('Email', state.newCommunity.email);
        formData.append('PhoneNumber', state.newCommunity.phoneNumber);
        formData.append('WhatsApp', state.newCommunity.whatsAppLink);
        formData.append('Website', state.newCommunity.website);
        formData.append('ID', state.newCommunity.id.toUpperCase());

        if (!validEmail(state.newCommunity.email)) {
            Swal.fire(
                'Invalid Lead Email',
                `Enter a valid email`,
                'warning'
            );
            return;
        }

        if (!validString(state.newCommunity.id)) {
            Swal.fire(
                'Community ID can not be empty',
                `Enter a valid community ID`,
                'warning'
            );
            return;
        }

        /* send request to correct service */
        showLoadingScreen();
        postRequestTo(
            "admin",
            formData,
            newCommunitySuccess,
            function (response) {
                console.log(response)
            }
        );
    }

    function newCommunitySuccess(response) {
        Swal.close();
        checkPermissionError(response);
        if (response.includes("created")) {
            Swal.fire(
                'New community created',
                `Community ID: "${state.newCommunity.id}"`,
                'success'
            );
            setState({
                ...state, newCommunity: {
                    ...state.newCommunity,
                    name: "",
                    location: "",
                    size: "",
                    email: "",
                    phoneNumber: "",
                    whatsAppLink: "",
                    website: "",
                    id: "",
                }
            })
        }
        if (response.includes("exists")) {
            Swal.fire(
                'Community already exists',
                `Community with ID: "${state.newCommunity.id}" already exists, try again with a different ID`,
                'warning'
            );
        }
    }

    function searchCommunity() {
        const formData = new FormData();
        formData.append('Username', props.username);
        formData.append('Password', props.password);
        formData.append('Action', "RETRIVE_COMMUNITY_INFO");
        formData.append('ID', state.search.toLocaleUpperCase());
        showLoadingScreen();

        if (!validString(state.search)) {
            Swal.fire(
                'Community ID can not be empty',
                `Enter a valid community ID to search`,
                'warning'
            );
            return;
        }

        postRequestTo(
            "admin",
            formData,
            function (response) {
                // console.log(response);
                Swal.close();
                if (response.includes("not") && response.includes("error")) {
                    Swal.fire(
                        'Invalid Community ID',
                        `Community with ID: "${state.search}" does not exist`,
                        'warning'
                    );
                }
                if (response.includes("Name") && response.includes("Location")) {
                    const obj = JSON.parse(response);
                    setState({
                        ...state,
                        modifyCommunity: {
                            ...state.modifyCommunity,
                            name: obj.Name,
                            location: obj.Location,
                            size: obj.Size,
                            email: obj.Email,
                            phoneNumber: obj.PhoneNumber,
                            whatsAppLink: obj.WhatsApp,
                            website: obj.Website,
                            id: obj.ID,
                        },
                        found: true,
                    })
                }
            },
            function (response) {
                console.log(response)
            }
        );
    }

    function saveChanges() {
        const formData = new FormData();
        formData.append('Username', props.username);
        formData.append('Password', props.password);
        formData.append('Action', "UPDATE_COMMUNITY_INFO");
        formData.append('ID', state.search.toLocaleUpperCase());
        formData.append('Name', state.modifyCommunity.name);
        formData.append('Location', state.modifyCommunity.location);
        formData.append('Size', state.modifyCommunity.size);
        formData.append('Email', state.modifyCommunity.email);
        formData.append('PhoneNumber', state.modifyCommunity.phoneNumber);
        formData.append('WhatsApp', state.modifyCommunity.whatsAppLink);
        formData.append('Website', state.modifyCommunity.website);
        formData.append('ID', state.modifyCommunity.id.toUpperCase());

        if (!validEmail(state.modifyCommunity.email)) {
            Swal.fire(
                'Invalid Lead Email',
                `Enter a valid email`,
                'warning'
            );
            return;
        }
        showLoadingScreen("Saving Changes");
        postRequestTo(
            "admin",
            formData,
            function (response) {
                Swal.close();
                checkPermissionError(response);
                if (response.includes("updated")) {
                    Swal.fire(
                        'Saved',
                        `All changes have been saved`,
                        'success'
                    );
                }

            },
            function (response) {
                console.log(response)
            }
        );
    }

    return <React.Fragment>
        <div className="pagetitle">
            <h1>Communities</h1>
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Admin</li>
                    <li className="breadcrumb-item">Communities</li>
                </ol>
            </nav>
        </div>
        <div className="row">
            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Create new community</h5>
                        <form onSubmit={preventDefault}>
                            <div className="row mb-3">
                                <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community name</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" maxLength={64} value={state.newCommunity.name} onChange={(e) => { setState({ ...state, newCommunity: { ...state.newCommunity, name: e.target.value } }) }} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community location</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" maxLength={64} value={state.newCommunity.location} onChange={(e) => { setState({ ...state, newCommunity: { ...state.newCommunity, location: e.target.value } }) }} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community size</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" maxLength={8} value={state.newCommunity.size} onChange={(e) => { setState({ ...state, newCommunity: { ...state.newCommunity, size: e.target.value } }) }} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community lead email</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" maxLength={64} value={state.newCommunity.email} onChange={(e) => { setState({ ...state, newCommunity: { ...state.newCommunity, email: e.target.value } }) }} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community lead phone number</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" maxLength={64} value={state.newCommunity.phoneNumber} onChange={(e) => { setState({ ...state, newCommunity: { ...state.newCommunity, phoneNumber: e.target.value } }) }} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community WhatsApp link</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" maxLength={512} value={state.newCommunity.whatsAppLink} onChange={(e) => { setState({ ...state, newCommunity: { ...state.newCommunity, whatsAppLink: e.target.value } }) }} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community Website link</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" maxLength={512} value={state.newCommunity.website} onChange={(e) => { setState({ ...state, newCommunity: { ...state.newCommunity, website: e.target.value } }) }} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community ID</label>
                                <div className="col-sm-8">
                                    <input type="text" className="form-control" maxLength={64} value={state.newCommunity.id} onChange={(e) => { setState({ ...state, newCommunity: { ...state.newCommunity, id: e.target.value } }) }} />
                                </div>
                            </div>
                            <div className="col-sm-10">
                                <button type="submit" className="btn btn-primary" onClick={createNewCommunity}>Create new community</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="col-lg-6">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">Modify existing community</h5>
                        <form onSubmit={preventDefault}>
                            <div className="row mb-3">
                                <div className="col-sm-10">
                                    <input type="text" className="form-control" placeholder="Enter community ID" value={state.search} onChange={(e) => { setState({ ...state, search: e.target.value, found: false }) }} />
                                </div>
                                <div className="col-sm-2">
                                    <button type="submit" className="btn btn-primary" onClick={searchCommunity}>Search</button>
                                </div>
                            </div>
                        </form>
                        {
                            (state.found) ?
                                <form onSubmit={preventDefault}>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community name</label>
                                        <div className="col-sm-8">
                                            <input type="text" className="form-control" maxLength={64} value={state.modifyCommunity.name} onChange={(e) => { setState({ ...state, modifyCommunity: { ...state.modifyCommunity, name: e.target.value } }) }} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community location</label>
                                        <div className="col-sm-8">
                                            <input type="text" className="form-control" maxLength={64} value={state.modifyCommunity.location} onChange={(e) => { setState({ ...state, modifyCommunity: { ...state.modifyCommunity, location: e.target.value } }) }} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community size</label>
                                        <div className="col-sm-8">
                                            <input type="text" className="form-control" maxLength={8} value={state.modifyCommunity.size} onChange={(e) => { setState({ ...state, modifyCommunity: { ...state.modifyCommunity, size: e.target.value } }) }} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community lead email</label>
                                        <div className="col-sm-8">
                                            <input type="text" className="form-control" maxLength={64} value={state.modifyCommunity.email} onChange={(e) => { setState({ ...state, modifyCommunity: { ...state.modifyCommunity, email: e.target.value } }) }} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community lead phone number</label>
                                        <div className="col-sm-8">
                                            <input type="text" className="form-control" maxLength={64} value={state.modifyCommunity.phoneNumber} onChange={(e) => { setState({ ...state, modifyCommunity: { ...state.modifyCommunity, phoneNumber: e.target.value } }) }} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community WhatsApp link</label>
                                        <div className="col-sm-8">
                                            <input type="text" className="form-control" maxLength={512} value={state.modifyCommunity.whatsAppLink} onChange={(e) => { setState({ ...state, modifyCommunity: { ...state.modifyCommunity, whatsAppLink: e.target.value } }) }} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <label htmlFor="inputText" className="col-sm-4 col-form-label">Enter community Website link</label>
                                        <div className="col-sm-8">
                                            <input type="text" className="form-control" maxLength={512} value={state.modifyCommunity.website} onChange={(e) => { setState({ ...state, modifyCommunity: { ...state.modifyCommunity, website: e.target.value } }) }} />
                                        </div>
                                    </div>
                                    <div className="col-sm-10">
                                        <button type="submit" className="btn btn-primary" onClick={saveChanges}>Save Changes</button>
                                    </div>
                                </form>
                                : <span></span>
                        }
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
}