import SubscribeForm from "../global/subscribeForm/SubscribeForm";
import { hrefValue, launchLink } from "../../resources";
import { Link, useNavigate } from "react-router-dom";
import React from "react";

export default function Footer() {

    const toRoute = useNavigate();

    return <React.Fragment>
        <footer id="footer" className="footer">
            {/* <div className="footer-newsletter">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-12 text-center">
                            <h4>Subscribe to our newsletter</h4>
                            <p>
                                Know the latest developments and opportunities in the tech space. <br />
                                Stay informed and entertained, for free.
                            </p>
                        </div>
                        <div className="col-lg-6">
                            <form>
                                <input type="email" name="email" placeholder="Enter email address" className="newsletter-email" />
                                <input type="submit" value="Subscribe" className="newsletter-subscribe" onClick={(e) => { e.preventDefault() }} />
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="footer-top">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-lg-5 col-md-12 footer-info">
                            <Link to="/" className="logo d-flex align-items-center">
                                <img src="/assets/img/icon/icon.svg" alt="TechVerse Logo" onClick={() => { toRoute('/') }} />
                                <span onClick={() => { toRoute('/') }}>TechVerse</span>
                            </Link>
                            <p>
                                a technology ecosystem
                            </p>
                            <div className="social-links mt-3">
                                <a href={hrefValue} className="instagram" onClick={(e) => { e.preventDefault(); launchLink("social_instagram") }}>
                                    <i className="bi bi-instagram"></i>
                                </a>
                                <a href={hrefValue} className="linkedin" onClick={(e) => { e.preventDefault(); launchLink("social_linkedin") }}>
                                    <i className="bi bi-linkedin"></i>
                                </a>
                                <a href="mailto:techversecommunities@gmail.com" className="whatsapp noLink">
                                    <i className="bi bi-google"></i>
                                </a>
                                {/* <a href={hrefValue} className="whatsapp" onClick={(e) => { e.preventDefault(); launchLink("social_whatsapp") }}>
                                    <i className="bi bi-whatsapp"></i>
                                </a>
                                <a href={hrefValue} className="telegram" onClick={(e) => { e.preventDefault(); launchLink("social_telegram") }}>
                                    <i className="bi bi-telegram"></i>
                                </a> */}
                            </div>
                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4>Useful Links</h4>
                            <ul>
                                <li>
                                    <i className="bi bi-chevron-right"></i>
                                    <Link to="/">Home</Link>
                                </li>
                                {/* <li>
                                    <i className="bi bi-chevron-right"></i>
                                    <Link to="/communities">Communities</Link>
                                </li> */}
                                <li>
                                    <i className="bi bi-chevron-right"></i>
                                    <Link to="/partners">Partners</Link>
                                </li>
                                <li>
                                    <i className="bi bi-chevron-right"></i>
                                    <Link to="/certificatePortal">Certificate Portal</Link>
                                </li>
                                <li>
                                    <i className="bi bi-chevron-right"></i>
                                    <Link to="/pressRelease">Press Release</Link>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-2 col-6 footer-links">
                            <h4>other links</h4>
                            <ul>
                                <li>
                                    <i className="bi bi-chevron-right"></i>
                                    <Link to="/termsOfService">Terms of service</Link>
                                </li>
                                <li>
                                    <i className="bi bi-chevron-right"></i>
                                    <Link to="/privacyPolicy">Privacy policy</Link>
                                </li>
                                <li>
                                    <i className="bi bi-chevron-right"></i>
                                    <Link to="/investorsPortal">Investors Portal</Link>
                                </li>
                            </ul>
                        </div>

                        <div
                            className="col-lg-3 col-md-12 footer-contact text-center text-md-start"
                        >
                            <h4>Contact Us</h4>
                            <p>
                                <i className="bi bi-geo-alt"></i>&nbsp; Spanish Town, Virgin Gorda - VG1150<br />
                                British Virgin Islands  <br /><br />
                                {/* Communities Manager Jaipur, Mr. Palash Chatterjee &nbsp;
                                <a href="tel:+917737667791" className="noLink"><i className="bi bi-telephone"></i> &nbsp;Call</a> <br />
                                <br />
                                Communities Manager Noida, Mr. Ashwant Kumar &nbsp;
                                <a href="tel:+91898320585" className="noLink"><i className="bi bi-telephone"></i> &nbsp; Call</a> <br />
                                <br /> */}
                                {/* <strong>Phone:</strong> +1 5589 55488 55<br /> */}
                                <a href="mailto:techversecommunities@gmail.com" className="noLink"><i className="bi bi-envelope"></i> &nbsp; Email: techversecommunities@gmail.com</a> <br />
                                {/* <a href="tel:+917737667791" className="noLink"><i className="bi bi-telephone"></i> &nbsp; Phone: +91 7737667791 (Partner Program Head)</a> <br /> */}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong><span>TechVerse</span></strong
                    >. All Rights Reserved
                </div>
                {/* <div className="credits">
                    Bootstrap Theme by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                </div> */}
            </div>
        </footer>
        <SubscribeForm />
    </React.Fragment>
}