import { Step1, Step2Student, Step2WorkingProfessional, Step3 } from "./Steps";
import { preferredCountries } from "./SubscribeFormData";
import { postRequestTo } from "../../../resources";
import React, { useEffect } from "react";
import "./SubscribeForm.css";
import $ from "jquery";

/* global Swal */
export default function SubscribeForm() {

    function validEmail(email) {
        return String(email).toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    function validString(str) {
        return str.replace(/\s/g, "").length;
    }

    function validSelect(id) {
        return $(`#${id}`).find(":selected").val() !== "select";
    }

    function step1() {
        /* hide other steps and show step 1 */
        $(".crdfm").removeClass("active");
        $(".formStep1").addClass("active");

        /* for next click */
        $(".next-btn").off("click");
        $(".next-btn").on("click", function () {
            if (!validString($("#firstName").val())) {
                Swal.fire(
                    "",
                    "Please fill in your first name",
                    "warning"
                );
                return;
            }
            if (!validString($("#lastName").val())) {
                Swal.fire(
                    "",
                    "Please fill in your last name",
                    "warning"
                );
                return;
            }
            if (!validSelect("occupation")) {
                Swal.fire(
                    "",
                    "Please select an option from the drop down",
                    "warning"
                );
                return;
            }
            step2();
        });
    }

    function step2() {
        /* hide other steps and show step 2 depending upon the value of student or employee */
        $(".crdfm").removeClass("active");

        /* form validation for student */
        if ($("#occupation").find(":selected").val() === "student_or_intern") {
            $(".formStep2Student").addClass("active");
            $(".btbc").off('click');
            $(".btbc").on('click', function () {
                step1();
            });
            $(".next-btn").off("click");
            $(".next-btn").on("click", function () {
                if (!validString($("#institute").val())) {
                    Swal.fire(
                        "",
                        "Please fill in your institute name",
                        "warning"
                    );
                    return;
                }
                if (!validSelect("educationLevel")) {
                    Swal.fire(
                        "",
                        "Please select education level",
                        "warning"
                    );
                    return;
                }
                if (!validSelect("graduationYear")) {
                    Swal.fire(
                        "",
                        "Please select graduation year",
                        "warning"
                    );
                    return;
                }
                if (!validSelect("qualification")) {
                    Swal.fire(
                        "",
                        "Please select qualification",
                        "warning"
                    );
                    return;
                }
                if (!validSelect("specialization")) {
                    Swal.fire(
                        "",
                        "Please select specialization",
                        "warning"
                    );
                    return;
                }
                step3();
            });
        }

        /* form validation for employee */
        if ($("#occupation").find(":selected").val() === "working_professional_or_employee") {
            $(".formStep2Employee").addClass("active");
            $(".btbc").off('click');
            $(".btbc").on('click', function () {
                step1();
            });
            $(".next-btn").off("click");
            $(".next-btn").on("click", function () {
                if (!validString($("#organization").val())) {
                    Swal.fire(
                        "",
                        "Please fill in your organization name",
                        "warning"
                    );
                    return;
                }
                if (!validSelect("industry")) {
                    Swal.fire(
                        "",
                        "Please select industry",
                        "warning"
                    );
                    return;
                }
                if (!validSelect("company_size")) {
                    Swal.fire(
                        "",
                        "Please select company size",
                        "warning"
                    );
                    return;
                }
                if (!validSelect("division")) {
                    Swal.fire(
                        "",
                        "Please select division",
                        "warning"
                    );
                    return;
                }
                if (!validSelect("position")) {
                    Swal.fire(
                        "",
                        "Please select position",
                        "warning"
                    );
                    return;
                }
                step3();
            });
        }
    }

    function step3() {
        /* hide other steps and show step 3*/
        $(".crdfm").removeClass("active");
        $(".formStep3").addClass("active");
        $(".btbc").off('click');
        $(".btbc").on('click', function () {
            step2();
        });
        $(".submit-subscribe-form").off("click");
        $(".submit-subscribe-form").on("click", function () {
            if (!validString($("#phoneNumber").val())) {
                Swal.fire(
                    "",
                    "Please enter phone number",
                    "warning"
                );
                return;
            }
            if (!validString($("#postalCode").val())) {
                Swal.fire(
                    "",
                    "Please enter postal code",
                    "warning"
                );
                return;
            }
            if ($(".selected-flag").attr("title").includes("India")) {
                if (!RegExp(/^[6-9]\d{9}$/gi).test($("#phoneNumber").val())) {
                    Swal.fire(
                        "",
                        "Indian phone number not valid, change the county code if you wish to use a different country's phone number",
                        "warning"
                    );
                    return;
                }
                if (!RegExp(/^[1-9][0-9]{5}$/gi).test($("#postalCode").val())) {
                    Swal.fire(
                        "",
                        "Indian postal code not valid, change the county code if you wish to use a different country's postal code",
                        "warning"
                    );
                    return;
                }
            }
            submit();
        });
    }

    function submit() {
        const formData = new FormData();
        formData.append('FirstName', $("#firstName").val());
        formData.append('LastName', $("#lastName").val());
        formData.append('Email', window['newsletterEmail']);
        formData.append('PhoneNumber', $("#phoneNumber").val());
        formData.append('Occupation', $("#occupation").val());
        formData.append('PostalCode', $("#postalCode").val());
        formData.append('AddToCommunity', $("#addToCommunity").val());
        if ($("#occupation").val() === 'student_or_intern') {
            formData.append('Institute', $("#institute").val());
            formData.append('EducationLevel', $("#educationLevel").val());
            formData.append('GraduationYear', $("#graduationYear").val());
            formData.append('Qualification', $("#qualification").val());
            formData.append('Specialization', $("#specialization").val());
        }
        if ($("#occupation").val() === 'working_professional_or_employee') {
            formData.append('Organization', $("#organization").val());
            formData.append('Industry', $("#industry").val());
            formData.append('CompanySize', $("#companySize").val());
            formData.append('Division', $("#division").val());
            formData.append('Position', $("#position").val());
        }

        /* send request to correct service */
        postRequestTo(
            "subscribe",
            formData,
            success,
            function (response) {
                console.log(response)
            }
        );
    }

    function success(response) {
        console.log(response)
        if (response.includes("success")) {
            $(".subscribe-form-wrap").removeClass("from-visible");
            Swal.fire(
                'Subscribed',
                'See you soon',
                'success'
            )
        }
        if (response.toLowerCase().includes("error") && response.toLowerCase().includes("exists")) {
            Swal.fire({
                title: 'Seems like you are already a subscriber',
                text: window["newsletterEmail"] + ' is already subscribed to our newsletter, would you like to view or update your information?',
                icon: 'success',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No, thanks`,
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isConfirmed) {
                    $(".subscribe-form-wrap").removeClass("from-visible");
                    window.open(window.location.origin + "/informationCenter", "_blank");
                } else if (result.isDenied) {
                    $(".subscribe-form-wrap").removeClass("from-visible");
                }
            })
        }
    }

    useEffect(function () {
        /* set window["newsletterEmail"] to input value */
        $(".newsletter-email").on("keyup", function () {
            window["newsletterEmail"] = $(this).val();
            $(".newsletter-email").val(window["newsletterEmail"]);
        });

        /* handle subscribe */
        $(".newsletter-subscribe").on("click", function () {
            if (validEmail(window["newsletterEmail"])) {
                $(".subscribe-form-wrap").addClass("from-visible");
                step1();
            } else {
                Swal.fire(
                    "Enter a valid email",
                    "Try checking for any mistakes in spelling or format",
                    "warning"
                );
            }
        });

        $(".btn-close-form").on("click", function () {
            $(".subscribe-form-wrap").removeClass("from-visible");
        });

        // eslint-disable-next-line
        eval(`$("#phoneNumber").intlTelInput({
            utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/8.4.6/js/utils.js",
            preferredCountries: ${JSON.stringify(preferredCountries)}
        });`);

        $(".subscribe-link").on("click", function () {
            Swal.fire({
                title: 'Enter your email',
                input: 'email',
                confirmButtonText: 'Done',
                inputAttributes: {
                    autocapitalize: 'off',
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    window["newsletterEmail"] = result.value;
                    $(".newsletter-email").val(window["newsletterEmail"]);
                    if (validEmail(window["newsletterEmail"])) {
                        $(".subscribe-form-wrap").addClass("from-visible");
                        step1();
                    } else {
                        Swal.fire(
                            "Enter a valid email",
                            "Try checking for any mistakes in spelling or format",
                            "warning"
                        );
                    }
                }
            })
        });

        $("input[type='text']").attr("maxlength", 64);

        // eslint-disable-next-line
    }, []);

    return <React.Fragment>
        <div className="subscribe-form-wrap">
            <button type="button" className="btn-close btn-close-form" aria-label="close"></button>
            <main>
                <div className="container">
                    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="crds col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                    <h2> Welcome to the <span className="l">TechVerse</span> </h2> <br />
                                    To help us provide you with the most relevant content, news & opportunities, just answer a few quick questions.
                                    {Step1}
                                    {Step2Student}
                                    {Step2WorkingProfessional}
                                    {Step3}
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </div>
    </React.Fragment>
}