import WebPage from "../../components/webpage/WebPage";
import { csvJSON, postRequestTo, preventDefault } from "../../resources";
import React, { useEffect, useState } from "react";
import "./investorsPortal.css";
import $ from "jquery";

export default function CertificatePortal(props) {

    const [state, setState] = useState({
        loaded: false,
        certificates: {},
        statusCSS: { display: "none" },
        certificateCSS: {},
        investorID: (new URL(window.location.href).searchParams.get("investorID") === null || new URL(window.location.href).searchParams.get("investorID") === undefined || new URL(window.location.href).searchParams.get("investorID") === false) ? "" : new URL(window.location.href).searchParams.get("investorID"),
        showCertificate: false,
        certificateURL: "",
        name: "",
        shares: 0,
        pricePerShare: 0,
        totalShares: 0,
        pAndN: 0,
        authMail: "",
        activityLog: "",
    })

    useEffect(() => {
        const formData = new FormData();
        postRequestTo(
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vQGI2X4pamKGUzpcwDp2qAS9akruGZa3G9a-rqQhAbRJY5a6DH-07PMazM_iJTtFoIRxrYaAGui0xG0/pub?output=csv",
            formData,
            (response) => {
                const json = csvJSON(response)
                // console.log("response")
                if (response.includes("Name")) {
                    const certificates = JSON.parse(json);
                    setState({
                        ...state,
                        loaded: true,
                        statusCSS: { display: "none" },
                        certificates: certificates,
                    })
                    search()
                }
            },
            (response) => {
                console.log(response);
            },
            true
        )
        // eslint-disable-next-line
    }, [])

    function oninvestorIDChange(e) {
        setState({ ...state, investorID: e.target.value, statusCSS: { display: "none" } })
    }

    function search() {
        if (!state.loaded) {
            setTimeout(() => {
                $("#searchButton").trigger("click");
            }, 10);
            return;
        }
        if (!state.investorID.replace(/\s/g, '').length) {
            return;
        }
        const stakeResults = state.certificates.filter((certificate) => {
            return certificate[0].toLowerCase() === state.investorID.trim().toLowerCase()
        })
        // console.log(state.investorID)
        if (stakeResults.length >= 1) {
            const stake = stakeResults[0];
            // console.log(stake)
            setState({
                ...state,
                showCertificate: true,
                name: stake[1],
                authMail: stake[2],
                shares: stake[3],
                pricePerShare: stake[4],
                pAndN: stake[5],
                totalShares: stake[6],
                activityLog: stake[7].split("<br>").map((e) => {
                    return <React.Fragment>
                        &gt; {e} <br />
                    </React.Fragment>
                }),
            })

        } else {
            setState({ ...state, statusCSS: { display: "block" } });
        }
    }

    function print() {
        /* canvas */
        const c = document.createElement("canvas");
        c.setAttribute("crossorigin", "anonymous");
        c.widli = 2000;
        c.height = 1414;

        /* context 2D */
        const ctx = c.getContext("2d");

        /* image */
        const img = document.createElement("img");
        img.setAttribute("crossorigin", "anonymous");
        img.src = state.certificateURL;
        document.body.append(img);

        img.onload = function () {
            ctx.drawImage(img, 0, 0);
            const data = c.toDataURL('image/png');
            download("certificate.png", data);
            img.remove();
        }
    }

    function download(filename, text) {
        var element = document.createElement('a');
        element.setAttribute('href', text);
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    function transferStake() {
        window.open(`mailto:techversecommunities@gmail.com?subject=Transfer_Of_Equity_Request&body=I_wish_to_transfer_my_stake`);
    }

    return <WebPage activePage="more">
        <section className="hero section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
                {
                    (!state.loaded) ?
                        <div className="dashboardLoaderWidget pricingLoaderWidget">
                            <div className="d-flex justify-content-center mt-5 text-primary">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        </div>
                        :
                        (!state.showCertificate) ?
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <h5 className="card-title text-center pb-0 fs-4 cpbh">Investors Portal</h5>
                                            <p className="text-center small">Viewing and managing your stake has never been simpler</p>
                                            <form className="row g-3 needs-validation" noValidate="" onSubmit={preventDefault}>
                                                <div className="col-12">
                                                    <label htmlFor="investorID" className="form-label">Enter Stakeholder ID </label>
                                                    <div className="input-group has-validation">
                                                        <input type="text" name="investorID" className="form-control" id="investorID" value={state.investorID} onChange={oninvestorIDChange} />
                                                        <div className="invalid-feedback" style={state.statusCSS}>Stakeholder ID does not exist.</div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <button className="btn btn-primary w-100" id="searchButton" type="submit" onClick={search}>Search</button>
                                                </div>
                                                <br />
                                                <div className="col-12">
                                                    <p className="small mb-0">lie investors portal is updated daily at 12am India Standard Time.</p>
                                                </div>
                                                <br />
                                                <br />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                    <div className="card mb-3">
                                        <div className="card-body lgp">
                                            <h5 className="card-title text-center pb-0 fs-4 cpbh">Investors Portal</h5>
                                            <p className="text-center small">Your stake at a glance</p>
                                            <br />
                                            <table className="table table-bordered" cellSpacing={5} cellPadding={12.5}>
                                                <tr>
                                                    <th>Stakeholder ID</th>
                                                    <td>{state.investorID.toUpperCase()}</td>
                                                </tr>
                                                <tr>
                                                    <th>Name</th>
                                                    <td>{state.name}</td>
                                                </tr>
                                                <tr>
                                                    <th>Shares (of Private Equity)</th>
                                                    <td>{state.shares}</td>
                                                </tr>
                                                <tr>
                                                    <th>Price per Share (of Private Equity)</th>
                                                    <td>₹{state.pricePerShare}</td>
                                                </tr>
                                                <tr>
                                                    <th>Total stake value</th>
                                                    <td><span style={{ color: '#089981', padding: 0, margin: 0 }}>₹{parseInt(state.pricePerShare) * parseInt(state.shares)}</span></td>
                                                </tr>
                                                <tr>
                                                    <th>Percent change from last round</th>
                                                    <td><span style={{ color: '#089981', padding: 0, margin: 0 }}>{state.pAndN}%</span></td>
                                                </tr>
                                            </table>
                                            <b style={{ marginBottom: "10px", display: "block" }}>Activity log:</b>
                                            <pre>{state.activityLog}</pre>
                                            <form className="row g-3 needs-validation" noValidate="" onSubmit={preventDefault}>
                                                <div className="col-12">
                                                    {/* <button className="btn btn-primary w-100" id="searchButton" type="submit" onClick={transferStake}>Transfer</button> */}
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                }
            </div>
        </section>
    </WebPage>
}