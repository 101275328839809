import WebPage from "../../components/webpage/WebPage";
import { csvJSON, postRequestTo, preventDefault } from "../../resources";
import React, { useEffect, useState } from "react";
import "./pressRelease.css";
import $ from "jquery";

export default function PressRelease(props) {

    const [state, setState] = useState({
        loaded: false,
        pressReleases: {},
        statusCSS: { display: "none" },
        certificateCSS: {},
    })

    useEffect(() => {
        const formData = new FormData();
        postRequestTo(
            "https://docs.google.com/spreadsheets/d/e/2PACX-1vTXcqRQUDYYKAHKNPTDMAJVl-f9u45dCYIV3W8nXpT0Ff0vJP8PElYaTT2W-Oxm1lUNij1JTkjlNcgS/pub?output=csv",
            formData,
            (response) => {
                const json = JSON.parse(csvJSON(response))
                // console.log(json)
                if (response.includes("/")) {
                    setState({
                        ...state,
                        loaded: true,
                        statusCSS: { display: "none" },
                        pressReleases: json,
                    })
                    // console.log(state)
                }
            },
            (response) => {
                console.log(response);
            },
            true
        )
        // eslint-disable-next-line
    }, [])

    return <WebPage activePage="more">
        <section className="hero section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
                {
                    (!state.loaded) ?
                        <div className="dashboardLoaderWidget pricingLoaderWidget">
                            <div className="d-flex justify-content-center mt-5 text-primary">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        </div>
                        :
                        <div className="row justify-content-center">
                            {state.pressReleases.map((p) => (
                                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center uipr" key={p[1]}>
                                    <a href={p[3]} style={{ color: "inherit" }} target="_blank">
                                        <b> &#91;{p[0]}&#93;</b>
                                        <span className="pr-type">{p[2]}</span>
                                        {p[1]}
                                    </a>
                                </div>
                            ))}
                        </div>
                }
            </div>
        </section>
    </WebPage>
}