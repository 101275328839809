import Collabrate from "../../../assets/img/communities/collabrate.png";
import Resources from "../../../assets/img/communities/resources.png";
import Learning from "../../../assets/img/communities/learning.png";
import Network from "../../../assets/img/communities/network.png";
import Prizes from "../../../assets/img/communities/prizes.png";
import Fun from "../../../assets/img/communities/fun.png";
import { aosDelay } from "../../../resources";

export default function BenefitsCommunitiesSection() {
    return <section id="values" className="values">
        <div className="container" data-aos="fade-up">
            <header className="section-header">
                <h2>why to join</h2>
                <p>Benefits of joining a community</p>
            </header>

            <div className="row">
                <div
                    className="col-lg-4"
                    data-aos="fade-up"
                    data-aos-delay={aosDelay}
                >
                    <div className="box">
                        <img
                            src={Learning}
                            className="img-fluid"
                            alt=""
                        />
                        <h3>Learning new skills</h3>
                        <p>
                            TechVerse Communities provide opportunities for individuals to learn about the latest technologies and trends in the industry.
                        </p>
                    </div>
                </div>

                <div
                    className="col-lg-4 mt-4 mt-lg-0"
                    data-aos="fade-up"
                    data-aos-delay={aosDelay}
                >
                    <div className="box">
                        <img
                            src={Network}
                            className="img-fluid"
                            alt=""
                        />
                        <h3>Building a network</h3>
                        <p>
                            TechVerse Communities offer a community of like-minded individuals who share an interest in technology. This network can be valuable for making connections, finding mentors, and exploring career opportunities.
                        </p>
                    </div>
                </div>

                <div
                    className="col-lg-4 mt-4 mt-lg-0"
                    data-aos="fade-up"
                    data-aos-delay={aosDelay}
                >
                    <div className="box">
                        <img
                            src={Resources}
                            className="img-fluid"
                            alt=""
                        />
                        <h3>Access to resources</h3>
                        <p>
                            TechVerse Communities have access to a range of resources provided by TechVerse, including training materials and software development tools.
                        </p>
                    </div>
                </div>
            </div>
            
            <br />

            <div className="row">
                <div
                    className="col-lg-4"
                    data-aos="fade-up"
                    data-aos-delay={aosDelay}
                >
                    <div className="box">
                        <img
                            src={Fun}
                            className="img-fluid"
                            alt=""
                        />
                        <h3>Fun and engaging</h3>
                        <p>
                            TechVerse Community activities are designed to be engaging and fun, providing members with a social and collaborative environment to learn and grow. This can help foster a love of learning and technology that can last a lifetime.
                        </p>
                    </div>
                </div>
                
                <div
                    className="col-lg-4 mt-4 mt-lg-0"
                    data-aos="fade-up"
                    data-aos-delay={aosDelay}
                >
                    <div className="box">
                        <img
                            src={Collabrate}
                            className="img-fluid"
                            alt=""
                        />
                        <h3>Collaborating on projects</h3>
                        <p>
                            TechVerse Communities offer opportunities to work on projects with other members, providing hands-on experience with real-world technology projects.
                        </p>
                    </div>
                </div>
                <div
                    className="col-lg-4 mt-4 mt-lg-0"
                    data-aos="fade-up"
                    data-aos-delay={aosDelay}
                >
                    <div className="box">
                        <img
                            src={Prizes}
                            className="img-fluid"
                            alt=""
                        />
                        <h3>Prizes, Swags and Goodies</h3>
                        <p>
                            TechVerse Communities distribute swags and goodies among the members during events. Members can also win prizes in competitions organized by the communities. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
}