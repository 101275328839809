// import PricingCalculator from "./pages/pricingCalculator/PricingCalculator";
import CertificatePortal from "./pages/certificatePortal/certificatePortal";
import InvestorsPortal from "./pages/investorsPortal/investorsPortal";
import PartnerSectionPage from "./pages/partnerSection/PartnerSection";
// import AdvertisersPage from "./pages/advertisers/Advertisers";
// import CommunitiesPage from "./pages/communities/Communities";
import PressRelease from "./pages/pressRelease/pressRelease";
import TermsOfService from "./pages/others/termsOfService";
import PrivacyPolicy from "./pages/others/privacyPolicy";
import PartnersPage from "./pages/partners/Partners";
import AdminPage from "./pages/admin/Admin";
import HomePage from "./pages/home/Home";

/* react-router-dom */
import { Navigate } from "react-router-dom";

const routes = [
    {
        path: "/home",
        page: <Navigate to="/" />
    },
    {
        path: "*",
        page: <Navigate to="/" />
    },
    {
        path: "/",
        page: <HomePage />
    },
    {
        path: "/admin",
        page: <AdminPage />
    },
    // {
    //     path: "/communities",
    //     page: <CommunitiesPage />
    // },
    {
        path: "/certificatePortal",
        page: <CertificatePortal />
    },
    {
        path: "/investorsPortal",
        page: <InvestorsPortal />
    },
    {
        path: "/partners",
        page: <PartnersPage />
    },
    {
        path: "/partners/section",
        page: <PartnerSectionPage />
    },
    {
        path: "/pressRelease",
        page: <PressRelease />
    },
    {
        path: "/privacyPolicy",
        page: <PrivacyPolicy />
    },
    {
        path: "/termsOfService",
        page: <TermsOfService />
    },
    // {
    //     path: "/advertisers",
    //     page: <AdvertisersPage />
    // }, 
    // {
    //     path: "/pricingCalculator",
    //     page: <PricingCalculator />
    // }, 
]

export default routes;