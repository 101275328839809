// import { hrefValue } from "../../resources";
import { Link } from "react-router-dom";

export default function Navigation(props) {

  return <header id="header" className="header fixed-top">
    <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
      <Link to="/" className="logo d-flex align-items-center">
        <img src="/assets/img/icon/icon.svg" alt="TechVerse Logo" />
        <span>TechVerse</span>
      </Link>

      <nav id="navbar" className="navbar">
        <ul>
          <li>
            <Link to="/" className={(props.activePage === "home") ? "nav-link forceActive" : "nav-link"}>Home</Link>
          </li>
          {/* <li>
            <Link to="/communities" className={(props.activePage === "communities") ? "nav-link forceActive" : "nav-link"}>Communities</Link>
          </li> */}
          <li className="dropdown">
            <Link to="/partners">
              <span className={(props.activePage === "partners") ? "nav-link forceActive" : "nav-link"}>Partners</span>
              <i className="bi bi-chevron-down"></i>
            </Link>
            <ul className="dropdownList">
              <li><Link to="/partners">Partner Program</Link></li>
              <li><Link to="/partners/section">Partner Section</Link></li>
            </ul>
          </li>
          <li className="dropdown">
            <Link to="/certificatePortal">
              <span className={(props.activePage === "more") ? "nav-link forceActive" : "nav-link"}>More</span>
              <i className="bi bi-chevron-down"></i>
            </Link>
            <ul>
              <li>
                <Link to="/certificatePortal">Certificate Portal</Link>
              </li>

              <li>
                <Link to="/pressRelease">Press Release</Link>
              </li>
              {/* <li>
                <Link to="/advertisers">Advertisers</Link>
              </li> */}
              {/* <li>
                <Link to="/pricingCalculator">Pricing Calculator</Link>
              </li> */}
              {/* <div className="dropdown-divider"></div> */}
            </ul>
          </li>
          <li>
            <a href="/#about" className="getstarted scrollto"> Know More </a>
          </li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>
    </div>
  </header>
}