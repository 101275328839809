import { aosDelay, hrefValue, launchLink, preventDefault } from "../../../resources";

export default function HeroCommunitiesSection() {
    return <section id="hero" className="hero d-flex align-items-center">
        <div className="container">
            <div className="row">
                <div
                    className="col-lg-6 d-flex flex-column justify-content-center"
                    data-aos="fade-up" data-aos-delay={aosDelay}
                >
                    <h1 id="appHeading">
                        Become a Leader
                    </h1>
                    <h2 >
                        Super boost your Career, become a TechVerse Lead. Build a community, grow your network, explore opportunities, intern with us. 
                    </h2>
                    <div className="aos-init aos-animate">
                        <div className="text-center text-lg-start" onClick={()=>{launchLink("partner_application")}}>
                            <a href={hrefValue} className="btn-get-started d-inline-flex align-items-center justify-content-center align-self-center" onClick={preventDefault}>
                                <span>Apply Now</span>
                                <i className="bi bi-arrow-right"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div
                    className="col-lg-6 hero-img"
                    data-aos="zoom-out"
                    data-aos-delay={aosDelay}
                >
                    <img
                        src="assets/img/partners/superhero.svg"
                        className="img-fluid"
                        alt=""
                    />
                </div>
            </div>
        </div>
    </section>
}